<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                Un panneau couvre en général approximativement les besoins en eau
                                chaude pour un ménage de 3 personnes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="my-2 solar-loader-icon" [hidden]="!showLoader">
                    <div>
                        <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
                    </div>
                    <div>
                        Simulation de votre <br />
                        projet en cours
                    </div>
                </div>

                <div [hidden]="showLoader">
                    <div class="container-fluid position-relative solar-card mb-3" *ngIf="slider">
                        <h5 class="text-st-primary mb-3">{{ adjustInstallationSizeLabel }}</h5>

                        <stRange
                            customClass="mb-3"
                            innerId="thermal-module-count"
                            [min]="1"
                            [max]="slider.maxModuleCount"
                            [step]="1"
                            [value]="slider.moduleCount"
                            [(ngModel)]="slider.moduleCount"
                            (change)="updateModuleCountByBuilding()"
                        >
                        </stRange>

                        <div class="row mb-2">
                            <div class="col-auto panel-count">
                                {{ slider.moduleCount }} {{ slider.moduleCount == 1 ? 'panneau' :
                                'panneaux' }}
                            </div>
                            <div class="col d-flex justify-content-end panel-area">
                                {{ slider.moduleArea }} m²
                            </div>
                        </div>
                    </div>

                    <cadastre-solaire-result-card
                        customClass="mb-3"
                        iconSvg="list_check"
                        [titleLabel]="titleInvestment"
                        [contentHtml]="htmlThInvestment"
                        [modalContentHtml]="tooltipThInvestmentDetail"
                    ></cadastre-solaire-result-card>

                    <cadastre-solaire-result-card
                        customClass="mb-3"
                        iconSvg="bolt"
                        [titleLabel]="titleProduction"
                        [contentHtml]="htmlThProduction"
                        [modalContentHtml]="tooltipThProductionDetail"
                    ></cadastre-solaire-result-card>

                    <cadastre-solaire-result-card
                        icon="fa fa-eur fa-2x"
                        [titleLabel]="titleEconomy"
                        [contentHtml]="htmlThEconomy"
                        [modalContentHtml]="tooltipThEconomyDetail"
                    ></cadastre-solaire-result-card>

                    <ng-container *ngIf="!modularity.simulations">
                        <ng-container *ngIf="!!htmlThWarning">
                            <div class="mt-3" [innerHtml]="htmlThWarning"></div>
                        </ng-container>
                        <ng-container *ngIf="!htmlThWarning">
                            <div class="text-center mt-3">
                                <small>
                                    Ces données sont issues d'un traitement automatisé et sont un
                                    premier ordre d'idée. Cela ne remplace pas la visite d'un
                                    technicien agréé.
                                </small>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="mt-3" *ngIf="modularity.downloadResults && !modularity.simulations">
                        <button
                            type="button"
                            class="btn btn-st-primary solar-pdf"
                            (click)="downloadPdf()"
                            [disabled]="isResultsDownloading"
                        >
                            <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                            <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top" [hidden]="showLoader">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma simulation"
                        (onClick)="validate()"
                        *ngIf="modularity.simulations"
                    >
                    </stButton>
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        [btnLabel]="contactButtonLabel"
                        (onClick)="openContactModal()"
                        *ngIf="!modularity.simulations"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
