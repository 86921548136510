import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';

import {
    PvSurplusConsumersResults,
    PvSurplusResult,
    PvResults,
    HousingConsumptionInputs,
} from 'src/app/models/Solar';

import { FilesService } from 'src/app/services/FilesService';
import { RestService } from '../RestService';
import { SolarService } from './cadastre-solaire.service';
import { TerService } from '../TerService';
import { UsefulService } from '../UsefulService';

@Injectable({
    providedIn: 'root',
})
export class SolarPvSurplusService {
    public pvSurplusStageObs = new BehaviorSubject<string>(null);
    public pvSurplusStageObs$: Observable<string> = this.pvSurplusStageObs.asObservable();

    public pvSurplusResultObs = new BehaviorSubject<PvSurplusResult>(null);
    public pvSurplusResultObs$: Observable<PvSurplusResult> =
        this.pvSurplusResultObs.asObservable();

    public isElectricMobilityDefined: boolean = false;
    public pvInfo: any;

    private equipments: any = [];

    public equipmentsData: {
        occupantCount: number;
        usableFloorArea: number;
        uses: { value: string; label: string; active: boolean; message: string }[];
    };

    public typologyData: { value: string; label: string };

    public consumptionData: {
        active: boolean;
        energyConsumed: number;
        subscribedPower: number;
        occupantCount: number;
        usableFloorArea: number;
        uses: { value: string; label: string; active: boolean; message: string }[];
        electricMobility: {
            annualDistance: number;
            chargingSchedule: string;
            chargingPoint: string;
            chargingFrequency: number;
        };
    };

    public electricMobilityData: {
        isOptimal: boolean;
        electricVehiculeCount: number;
        annualDistance: number;
        chargingSchedule: string;
        chargingPoint: string;
        chargingFrequency: number;
    };

    public importedElectricLoad: {
        isLoading: boolean;
        start: Date;
        end: Date;
        fillRate: number;
        isValid: boolean;
        error: string;
        load: Array<{ date: Date; value: number }>;
        energy: number;
        subscribedPower: number;
    };

    public pvSurplusResults: PvSurplusResult[];
    public pvSurplusConsumersResults: PvSurplusConsumersResults;
    public currentResult: PvSurplusResult;

    public bonus: number;
    public investment: number;
    public totalPowerPeak: number;
    public totalModuleCount: number;
    public totalModuleArea: number;

    public electricMobilityDistanceFromSelfConsumption: number;
    public electricMobilityDistanceRatio: number;
    public electricMobilityAnnualSelfConsumption: number;
    public electricMobilityEconomy: number;
    public optimalElectricMobilityEconomy: number;
    public optimalElectricMobilityAnnualSelfConsumption: number;

    public totalProduction: number;
    public selfConsumptionRate: number;
    public selfProductionRate: number;
    public selfConsumptionEnergy: number;
    public surplusEnergy: number;
    public saving: number;
    public sellingGain: number;
    public totalGain: number;
    public roi: number;
    public connectionCost: any;

    public electricMobilityDefaultAnnualDistance = 12000;

    constructor(
        @Inject(FilesService) private filesService: FilesService,
        @Inject(RestService) private restService: RestService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    clear() {
        this._clearObservables();
    }

    private _clearObservables() {
        this.pvSurplusStageObs.next(null);
        this.pvSurplusResultObs.next(null);
    }

    updateStage(stage: string) {
        this.pvSurplusStageObs.next(stage);
    }

    setPvInfo(pvInfo: any) {
        this.pvInfo = pvInfo;
        this.equipments = JSON.parse(JSON.stringify(pvInfo.equipments));
        this.equipmentsData.uses = JSON.parse(JSON.stringify(pvInfo.equipments));
    }

    initTypologyData() {
        this.typologyData = {
            value: 'housing',
            label: 'Résidentiel',
        };
    }

    initEquipmentsData() {
        this.equipmentsData = {
            occupantCount: null,
            usableFloorArea: null,
            uses: this.equipments.length ? this.equipments : [],
        };
    }

    initConsumptionData() {
        this.consumptionData = {
            active: false,
            energyConsumed: null,
            subscribedPower: null,
            occupantCount: null,
            usableFloorArea: null,
            uses: this.equipments.length ? this.equipments : [],
            electricMobility: {
                annualDistance: null,
                chargingSchedule: null,
                chargingPoint: null,
                chargingFrequency: null,
            },
        };
    }

    initEquipments() {
        this.equipmentsData.uses = this.equipments;
    }

    initImportedElectricLoad() {
        this.importedElectricLoad = {
            isLoading: false,
            start: null,
            end: null,
            fillRate: null,
            isValid: null,
            error: '',
            load: [],
            energy: null,
            subscribedPower: null,
        };
    }

    initElectricMobilityData() {
        this.electricMobilityData = {
            isOptimal: false,
            electricVehiculeCount: 1,
            annualDistance: this.electricMobilityDefaultAnnualDistance,
            chargingSchedule: null,
            chargingPoint: null,
            chargingFrequency: null,
        };
    }

    async downloadPvSurplus() {
        const elements = this.solarService.selectedElements.map((element, index) => ({
            latitude: element.center.latitude,
            longitude: element.center.longitude,
            height: element.height,
            tilt: element.tilt,
            azimuth: element.azimuth,
            usableRoofArea: element.usableRoofArea,
            exists: !element.isNew,
            installationType: element.installationType,
            numPanels: this.currentResult.numPanelsBySolarInstallation[index],
            numPanelsStart: this.currentResult.numPanelsBySolarInstallation[index],
        }));
        const data: any = {
            elements: elements,
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            typology: this.typologyData.value,
            electricLoadDates: this.importedElectricLoad.load.map((load) => load.date),
            electricLoadValues: this.importedElectricLoad.load.map((load) => load.value),
        };
        if (this.typologyData.value == 'housing') {
            const isCustomElectricLoadDefined = this.importedElectricLoad.load.length > 0;
            if (!isCustomElectricLoadDefined) {
                const uses = this.equipmentsData.uses
                    .filter((use) => use.active)
                    .map((use) => use.value);

                data.usableFloorArea = this.equipmentsData.usableFloorArea;
                data.occupantCount = this.equipmentsData.occupantCount;
                data.uses = uses;
                data.electricConsumption = this.consumptionData.energyConsumed;
            }
        }
        const buffer = await this.restService.downloadPvSurplus(data);
        return buffer;
    }

    async simulatePvSurplus(): Promise<PvResults> {
        const elements = this.solarService.selectedElements.map((element) => ({
            latitude: element.center.latitude,
            longitude: element.center.longitude,
            height: element.height,
            tilt: element.tilt,
            azimuth: element.azimuth,
            usableRoofArea: element.usableRoofArea,
            exists: !element.isNew,
            installationType: element.installationType,
        }));

        const data: any = {
            elements: elements,
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            typology: this.typologyData.value,
            electricLoadDates: this.importedElectricLoad.load.map((load) => load.date),
            electricLoadValues: this.importedElectricLoad.load.map((load) => load.value),
        };
        if (this.typologyData.value == 'housing') {
            const isCustomElectricLoadDefined = this.importedElectricLoad.load.length > 0;
            if (!isCustomElectricLoadDefined) {
                const uses = this.equipmentsData.uses
                    .filter((use) => use.active)
                    .map((use) => use.value);

                data.usableFloorArea = this.equipmentsData.usableFloorArea;
                data.occupantCount = this.equipmentsData.occupantCount;
                data.uses = uses;
                data.electricConsumption = Number(this.consumptionData.energyConsumed);
            }

            if (this.checkIsElectricMobilityDefined()) {
                data.electricMobility = this.electricMobilityData;
            }
        }
        const response = await this.restService.simulatePvSurplus(data);
        this.pvSurplusResults = response.selfConsumption.selfConsumptionByPanelCount;
        this.pvSurplusConsumersResults = response.selfConsumption.consumers;
        return response;
    }

    getAutoconsumption(data: any) {
        return this.restService.getAutoconsumption(data);
    }

    setCurrentResult(result: PvSurplusResult) {
        this.bonus = result.bonus;
        this.investment = result.investment;
        this.totalPowerPeak = result.peakPower;
        this.totalModuleCount = result.moduleCount;
        this.totalModuleArea = result.totalModuleArea;
        this.totalProduction = result.annualProduction;

        this.electricMobilityDistanceFromSelfConsumption =
            result.electricMobilityDistanceFromSelfConsumption;
        this.electricMobilityDistanceRatio = result.electricMobilityDistanceRatio;
        this.electricMobilityAnnualSelfConsumption = result.electricMobilityAnnualSelfConsumption;
        this.electricMobilityEconomy = result.electricMobilityEconomy;
        this.optimalElectricMobilityEconomy = result.optimalElectricMobilityEconomy;
        this.optimalElectricMobilityAnnualSelfConsumption =
            result.optimalElectricMobilityAnnualSelfConsumption;

        this.selfConsumptionRate = result.selfConsumptionRate;
        this.selfProductionRate = result.selfProductionRate;
        this.selfConsumptionEnergy = result.selfConsumptionEnergy;
        this.surplusEnergy = result.surplusEnergy;
        this.saving = result.saving;
        this.sellingGain = result.sellingGain;
        this.totalGain = result.totalGain;
        this.roi = result.roi;

        this.updatePvSurplusResultObs(result);
    }

    updatePvSurplusResultObs(result: PvSurplusResult) {
        this.currentResult = result;
        this.pvSurplusResultObs.next(result);
    }

    getMaxModuleResult(): PvSurplusResult {
        return this.pvSurplusResults[this.pvSurplusResults.length - 1];
    }

    findResultByModuleCount(moduleCount: number) {
        return this.pvSurplusResults.find((result) => result.moduleCount == moduleCount);
    }

    findOptimalResult(): PvSurplusResult {
        const optimalResult = this.pvSurplusResults
            .slice(1)
            .reduce(
                (optimal: PvSurplusResult, config: PvSurplusResult) =>
                    optimal.roi > config.roi ? config : optimal,
                this.pvSurplusResults[0],
            );
        return optimalResult;
    }

    setDefaultUsableFloorArea() {
        let buildingIds = this.solarService.selectedElements.map((element) => element.id_cons);
        buildingIds = [...new Set(buildingIds)];
        const usableFloorArea = buildingIds.reduce((usableFloorArea, buildingId) => {
            const element = this.solarService.selectedElements.find(
                (element) => element.id_cons == buildingId,
            );
            usableFloorArea += element.surface_habitable ? element.surface_habitable : 0;
            return usableFloorArea;
        }, 0);
        return Math.round(usableFloorArea);
    }

    setDefaultOccupantCount() {
        return 3;
    }

    async getHousingElectricityConsumption() {
        const uses = this.equipmentsData.uses
            .filter((usage: any) => usage.active)
            .map((usage: any) => usage.value);

        const parameters: HousingConsumptionInputs = {
            year: this.solarService.indicatorPlot.crrsdc_ter_year_geo,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
            usableFloorArea: this.equipmentsData.usableFloorArea,
            occupantCount: this.equipmentsData.occupantCount,
            uses: JSON.stringify(uses),
        };
        if (this.checkIsElectricMobilityDefined()) {
            parameters.electricMobility = JSON.stringify(this.electricMobilityData);
        }

        return await this.restService.getHousingElectricityConsumption(parameters);
    }

    async getDefaultMobilityUses() {
        // const parameters = {
        //     occupantCount: this.equipmentsData.occupantCount,
        //     year: this.solarService.indicatorPlot.crrsdc_ter_year_geo,
        //     scaleTypeId: this.terService.territoryScale.typeId,
        //     territoryIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
        // };

        // return await this.restService.getDefaultMobilityUses(parameters);
        return {
            electricVehiculeCount: 1,
            annualDistance: 12000,
        };
    }

    addConnectionCost(connectionCost: any) {
        const currentResult = this.currentResult;
        const totalGain = currentResult.totalGain;
        const initInvestment = currentResult.investment;
        currentResult.connectionCost = connectionCost;
        this.connectionCost = connectionCost;

        if (connectionCost.value) {
            currentResult.investment = initInvestment + connectionCost.value;
            currentResult.roi = currentResult.investment / totalGain;

            this.investment = currentResult.investment;
            this.roi = currentResult.roi;
        }
    }

    convertImportElectricLoadType1ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        // CDC prévert-CSV - Réelle
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: ['date', 'value'],
                range: 0,
                raw: false,
                defval: '',
            })
            .map((row: { date: string; value: string }) => ({
                date: new Date(row.date),
                value: row.value,
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    convertImportElectricLoadType2ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        // export_courbe_charges_sieds
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: 1,
                range: 1,
                raw: false,
                defval: '',
            })
            .map((row) => ({
                date: SolarPvSurplusService._parseDate(`${row[0]} ${row[1]}`),
                value: row[4],
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    convertImportElectricLoadType3ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        //  csv residentiel
        // let range = XLSX.utils.decode_range(ws['!ref']);
        // range.s.r = 2; // Skip first two rows
        // ws['!ref'] = XLSX.utils.encode_range(range);
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: 1,
                range: 3,
                raw: false,
                defval: '',
            })
            .map((row) => ({
                date: new Date(row[0]),
                value: row[1],
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    private static _parseImportElectricLoad(
        jsonData: Array<{ date: Date; value: string }>,
    ): Array<{ date: Date; value: number }> {
        try {
            const parsedData = jsonData.map((row) => ({
                date: new Date(row.date),
                value:
                    row.value == ''
                        ? null
                        : parseFloat(row.value.replace(/\s/g, '').replace(',', '.')),
            }));
            const isAnyNaNValue = parsedData.some((row) => isNaN(row.value));
            if (isAnyNaNValue) {
                const nanValue = parsedData.find((row) => isNaN(row.value));
                throw new Error(`Some values are NaN. Check row ${nanValue.date}`);
            }
            const isAnyInvalidDate = parsedData.some((row) => isNaN(row.date.getTime()));
            if (isAnyInvalidDate) {
                throw new Error('Some dates are invalid.');
            }

            const mostRecentParsedData =
                SolarPvSurplusService._getOnLyOneYearMostRecentData(parsedData);

            return mostRecentParsedData;
        } catch (error) {
            throw new Error(error);
        }
    }

    private static _parseDate(dateString: string) {
        // Split the date and time parts
        const [datePart, timePart] = dateString.split(' ');

        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('/').map(Number);

        // Split the time part into hours, minutes, and seconds
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        // Create and return the Date object
        return new Date(year, month - 1, day, hours, minutes, seconds);
    }

    private static _getOnLyOneYearMostRecentData(parsedData: Array<{ date: Date; value: number }>) {
        const mostRecentDate = new Date(parsedData[parsedData.length - 1].date);
        // Calculate the cutoff date (one year before the first object's date)
        const cutoffDate = new Date(mostRecentDate);
        cutoffDate.setFullYear(mostRecentDate.getFullYear() - 1);

        // Filter the array to include only objects with dates more recent than the cutoff date
        const mostRecentParsedData = parsedData.filter((obj) => new Date(obj.date) >= cutoffDate);
        return mostRecentParsedData;
    }

    async analyseElectricLoad(electricLoad: Array<{ date: Date; value: number }>): Promise<any> {
        const data = {
            dates: electricLoad.map((load) => load.date),
            values: electricLoad.map((load) => load.value),
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
        };
        return await this.restService.analyseElectricLoad(data);
    }

    async readImportedElectricLoad(file: any) {
        try {
            const rows = await this.filesService.readCsv(file);
            const importedElectricLoad = [];

            if (!rows.length) {
                throw 'File is empty.';
            }

            if (rows.length != 8763) {
                throw `File size in incorrect (reading ${rows.length}).`;
            }

            for (let i = 3; i < rows.length; i++) {
                const row = rows[i];
                const value = Number(row[1]);

                if (isNaN(value)) {
                    throw `${value} is not a number.`;
                }
                importedElectricLoad.push(value);
            }
            return importedElectricLoad;
        } catch (error) {
            console.error('Error readImportedElectricLoad', error);
            throw error;
        }
    }

    calcImportedElectricEnergyConsumed(load: number[]) {
        const energy = load.reduce((sum, value) => sum + value / 1e3, 0);
        return this.usefulService.round(energy);
    }

    calcImportedSubscribedPower(load: number[]) {
        const subscribedPower = Math.max(...load) / 1e3;
        return this.usefulService.round(subscribedPower);
    }

    static _setTooltipPosition(context: any, tooltipEl: any) {
        const { chart, tooltip } = context;
        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
        const position = chart.canvas.getBoundingClientRect();

        const tooltipWidth = tooltipEl.offsetWidth;
        let left = positionX + tooltip.caretX;

        // Ensure tooltip does not overflow the chart area
        if (left + tooltipWidth > position.right + window.scrollX) {
            left = position.right + window.scrollX - tooltipEl.offsetWidth;
        }
        if (left < position.left + window.scrollX) {
            left = position.left + window.scrollX;
        }

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = left + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    }

    checkIsElectricMobilityDefined(): boolean {
        this.isElectricMobilityDefined = this.equipmentsData.uses
            .filter((equipment) => equipment.active)
            .some((equipment) => equipment.value == 'electric_vehicule');
        return this.isElectricMobilityDefined;
    }
}
