import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarThermalService } from 'src/app/services/cadastre-solaire/cadastre-solaire-thermal.service';
import { UsefulService } from 'src/app/services/UsefulService';
import { CadastreSolaireContactModalComponent } from '../../modals/cadastre-solaire-contact-modal.component';
import { CadastreSolaireInfoModalComponent } from '../../modals/cadastre-solaire-info-modal.component';

@Component({
    selector: 'cadastreSolaireThermal',
    templateUrl: './cadastre-solaire-thermal.template.html',
})
export class CadastreSolaireThermalComponent implements OnInit, AfterViewInit {
    public showLoader: boolean = true;

    public modularity: any;
    public slider: { maxModuleCount: number; moduleCount: number; moduleArea: number };

    public tooltipThInfoDetail: any;
    public tooltipThInvestmentDetail: any;
    public tooltipThInstallationDetail: any;
    public tooltipThProductionDetail: any;
    public tooltipThEconomyDetail: any;

    public htmlThInvestment: string;
    public htmlThInstallation: string;
    public htmlThProduction: string;
    public htmlThEconomy: string;
    public htmlThWarning: string;

    public adjustInstallationSizeLabel: string;
    public contactButtonLabel: string;
    public isResultsDownloading: boolean;

    public titleInvestment: string = 'Investissement';
    public titleProduction: string = 'Production';
    public titleEconomy: string = 'Economies';

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(SolarThermalService) private solarThermalService: SolarThermalService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        this.tooltipThInfoDetail = this.solarThermalService.thInfo.message.info_detail;
        this.tooltipThInvestmentDetail =
            this.solarThermalService.thInfo.message.investissement_detail;
        this.tooltipThInstallationDetail =
            this.solarThermalService.thInfo.message.installation_detail;
        this.tooltipThProductionDetail = this.solarThermalService.thInfo.message.production_detail;
        this.tooltipThEconomyDetail = this.solarThermalService.thInfo.message.economie_detail;
        this.htmlThWarning = this.solarThermalService.thInfo.message.warning;

        const customAdjustInstallationSizeLabel =
            this.solarService.moduleInfo.ajuster_taille_installation;
        this.adjustInstallationSizeLabel = customAdjustInstallationSizeLabel
            ? customAdjustInstallationSizeLabel
            : "J'ajuste la taille de mon installation";

        const customContactButtonLabel = this.solarService.moduleInfo.contacts_bouton;
        this.contactButtonLabel = customContactButtonLabel
            ? customContactButtonLabel
            : 'Aller plus loin';
    }

    async ngAfterViewInit(): Promise<void> {
        await this.initThermalSimulation();

        const maxResult = this.solarThermalService.getMaxModuleResult();
        const minResult = this.solarThermalService.getMinModuleResult();
        this.solarThermalService.setCurrentResult(minResult);
        this.slider = {
            maxModuleCount: maxResult.moduleCount,
            moduleCount: minResult.moduleCount,
            moduleArea: this.usefulService.round(minResult.totalModuleArea),
        };

        this._updateThIndicator();

        const isNew = this.solarService.checkIfSelectedElementsIsNew();
        if (isNew) {
            this.solarService.setTotalModuleAreaToSelectionInfo(maxResult.totalModuleArea);
        }
    }

    async initThermalSimulation() {
        try {
            this.showLoader = true;
            await this.solarThermalService.simulateThermal();
        } catch (error) {
            console.error('Error initThermalSimulation', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser la simulation thermique.",
            );
            this.solarService.updateMainStage('technologyStage');
        } finally {
            this.showLoader = false;
        }
    }

    async downloadPdf() {
        try {
            this.isResultsDownloading = true;

            const results = {
                technology: 'thermal',
                label: 'Thermique',
                htmlInvestment: this.htmlThInvestment,
                htmlInstallation: this.htmlThInstallation,
                htmlProduction: this.htmlThProduction,
                htmlEconomy: this.htmlThEconomy,
                moduleCount: this.slider.moduleCount,
                moduleAreaStringified: this._stringifyInstallation(),
                investmentStringified: this._stringifyInvestment(),
                economyStringified: this._stringifyEconomy(),
                roiStringified: this._stringifyRoi(),
            };

            const buffer = await this.solarService.getSolarPdfFile(results);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            saveAs(blob, 'solaire-thermique.pdf');
        } catch (error) {
            console.error('Error downloadPdf', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de télécharger les résultats.',
            );
        } finally {
            this.isResultsDownloading = false;
        }
    }

    openContactModal() {
        const body =
            '<p> Vous voulez en savoir plus sur une installation solaire sur votre logement ? </p>';

        const modalRef = this.modalService.open(CadastreSolaireContactModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {},
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    cancel() {
        this.solarService.updateMainStage('technologyStage');
    }

    validate() {
        const result = {
            technology: 'thermal',
            label: 'Thermique',
            htmlInvestment: this.htmlThInvestment,
            htmlInstallation: this.htmlThInstallation,
            htmlProduction: this.htmlThProduction,
            htmlEconomy: this.htmlThEconomy,
            moduleCount: this.slider.moduleCount,
            moduleAreaStringified: this._stringifyInstallation(),
            investmentStringified: this._stringifyInvestment(),
            economyStringified: this._stringifyEconomy(),
            roiStringified: this._stringifyRoi(),
        };

        this.solarService.addResult(result);
        this.solarService.updateMainStage('resultsStage');
    }

    updateModuleCountByBuilding() {
        const result = this.solarThermalService.findResultByModuleCount(this.slider.moduleCount);
        this.solarThermalService.setCurrentResult(result);

        this.slider.moduleCount = result.moduleCount;
        this.slider.moduleArea = this.usefulService.round(result.totalModuleArea);

        this._updateThIndicator();
    }

    private _updateThIndicator() {
        this._updateThInvestment();
        this._updateThInstallation();
        this._updateThProduction();
        this._updateThEconomy();
    }

    private _updateThInvestment() {
        const splitByInvestmentTag =
            this.solarThermalService.thInfo.message.investissement.split('##th_investment');
        if (splitByInvestmentTag.length === 2) {
            let html = splitByInvestmentTag.join(
                `<span class="text-st-active">${this._stringifyInvestment()}</span>`,
            );

            html = `<span>${html}</span>`;
            this.htmlThInvestment = html;
        }
    }

    private _updateThInstallation() {
        let html = this.solarThermalService.thInfo.message.installation;

        // ##th_module_count
        const isThModuleCountDefined = html.includes('##th_module_count');
        if (isThModuleCountDefined) {
            const placeholder = `<span class="text-st-active">${this._stringifyTotalModuleCount()}</span>`;
            html = html.replace('##th_module_count', placeholder);
        }

        // ##th_total_module_area
        const isThModuleAreaDefined = html.includes('##th_total_module_area');
        if (isThModuleAreaDefined) {
            const placeholder = `<span class="text-st-active">${this._stringifyInstallation()}</span>`;
            html = html.replace('##th_total_module_area', placeholder);
        }

        html = `<span>${html}</span>`;
        this.htmlThInstallation = html;
    }

    private _updateThProduction() {
        // ##th_production_by_year
        const splitByProductionTag =
            this.solarThermalService.thInfo.message.production.split('##th_production_by_year');
        if (splitByProductionTag.length === 2) {
            const thProduction = Math.round(this.solarThermalService.totalProduction);
            const thProductionStingified = this.usefulService.stringifyNumber(thProduction);

            let html = splitByProductionTag.join(
                `<span class="text-st-active">${thProductionStingified}\u00a0kWh/an</span>`,
            );

            // ##shower_count
            const splitByShowerTag = html.split('##shower_count');
            if (splitByShowerTag.length === 2) {
                const thShowerCount = this.solarThermalService.totalShowerCount;
                let unit = 'douche';
                thShowerCount > 1 ? (unit += 's') : unit;
                const thShowerCountStringified = this.usefulService.stringifyNumber(thShowerCount);

                html = splitByShowerTag.join(
                    `<span class="text-st-active">${thShowerCountStringified} ${unit}</span>`,
                );
            }

            // ##person_count
            const splitByPersonTag = html.split('##person_count');
            if (splitByPersonTag.length === 2) {
                const thPersonCount = this.solarThermalService.totalPersonCount;
                let unit = 'personne';
                thPersonCount > 1 ? (unit += 's') : unit;
                const thPersonCountStringified = this.usefulService.stringifyNumber(thPersonCount);

                html = splitByPersonTag.join(
                    `<span class="text-st-active">${thPersonCountStringified} ${unit}</span>`,
                );
            }

            html = `<span>${html}</span>`;
            this.htmlThProduction = html;
        }
    }

    private _updateThEconomy() {
        // ##th_saving_by_year
        const splitBySavingTag =
            this.solarThermalService.thInfo.message.economie.split('##th_saving_by_year');
        if (splitBySavingTag.length === 2) {
            let html = splitBySavingTag.join(
                `<span class="text-st-active">${this._stringifyEconomy()}</span>`,
            );

            // ##th_roi
            const splitByRoiTag = html.split('##th_roi');
            if (splitByRoiTag.length === 2) {
                html = splitByRoiTag.join(
                    `<span class="text-st-active">${this._stringifyRoi()}</span>`,
                );
            }
            html = `<span>${html}</span>`;
            this.htmlThEconomy = html;
        }
    }

    private _stringifyTotalModuleCount() {
        const thTotalModuleCount = Math.round(this.solarThermalService.totalModuleCount);
        const thTotalModuleCountStingified = this.usefulService.stringifyNumber(thTotalModuleCount);
        const unit = thTotalModuleCount == 1 ? 'panneau' : 'panneaux';
        return `${thTotalModuleCountStingified}\u00a0${unit}`;
    }

    private _stringifyInstallation() {
        const thInstallationArea = Math.round(this.solarThermalService.totalModuleArea);
        const thInstallationAreaStingified = this.usefulService.stringifyNumber(thInstallationArea);
        return `${thInstallationAreaStingified}\u00a0m²`;
    }

    private _stringifyInvestment() {
        const thInvestment = Math.round(this.solarThermalService.investment);
        const thInvestmentStingified = this.usefulService.stringifyNumber(thInvestment);
        return `${thInvestmentStingified}\u00a0€`;
    }

    private _stringifyEconomy() {
        const thSaving = Math.round(this.solarThermalService.totalSaving);
        const thSavingStingified = this.usefulService.stringifyNumber(thSaving);
        return `${thSavingStingified}\u00a0€/an`;
    }

    private _stringifyRoi() {
        const thRoi = Math.round(this.solarThermalService.roi);
        let unit = 'an';
        thRoi > 1 ? (unit += 's') : unit;
        const thRoiStingified = this.usefulService.stringifyNumber(thRoi);
        return `${thRoiStingified} ${unit}`;
    }

    openInfoModal(body: string, title: string) {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }
}
