import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MobilityUses } from 'src/app/models/Solar';

import { CadastreSolaireInfoModalComponent } from '../../../../../../modals/cadastre-solaire-info-modal.component';

import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { UsefulService } from 'src/app/services/UsefulService';
import { distinctUntilChanged } from 'rxjs';

function chargingScheduleValidator(control: AbstractControl): ValidationErrors | null {
    const validValues = ['day', 'evening', 'night'];
    return validValues.includes(control.value) ? null : { invalidChargingSchedule: true };
}

function chargingPointValidator(control: AbstractControl): ValidationErrors | null {
    const validValues = ['reinforced_socket', 'standard_charging_station', 'fast_charging_station'];
    return validValues.includes(control.value) ? null : { invalidChargingPoint: true };
}

function chargingFrequencyValidator(control: AbstractControl): ValidationErrors | null {
    const validValues = [5, 3, 1];
    return validValues.includes(control.value) ? null : { invalidChargingFrequency: true };
}

@UntilDestroy()
@Component({
    selector: 'cadastreSolairePvSurplusElectricVehicule',
    templateUrl: './cadastre-solaire-pv-surplus-electric-vehicule.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-electric-vehicule.component.scss'],
})
export class CadastreSolairePvSurplusElectricVehiculeComponent implements OnInit {
    @ViewChild('emojiSmile') emojiSmile: TemplateRef<any>;
    @ViewChild('emojiNeutral') emojiNeutral: TemplateRef<any>;
    @ViewChild('emojiFrown') emojiFrown: TemplateRef<any>;

    public showMobileUi = false;

    public chargingScheduleActiveTabId: number = null;
    public chargingPointActiveTabId: number = null;
    public chargingFrequencyActiveTabId: number = null;

    public mobiliyUsesFormGroup: FormGroup;
    public isMobilityUsesFormValid: boolean = false;
    private defaultMobilityUses: MobilityUses;

    public electricVehiculeCountOptions = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
    ];

    private optimalChargingConfig = {
        chargingSchedule: 'day',
        chargingPoint: 'reinforced_socket',
        chargingFrequency: 5,
    };

    public chargingScheduleInfo = [
        {
            id: 1,
            value: 'day',
            label: 'Recharge en pleine journée',
            description: `
                Recharger en pleine journée maximise l'utilisation de votre installation solaire. 
                En alignant la recharge sur les heures de production solaire, vous réduisez votre dépendance au réseau et minimisez vos émissions de CO2.`,
            class: 'success',
            emoji: null,
        },
        {
            id: 2,
            value: 'evening',
            label: 'Recharge mixte (en journée et le soir)',
            description: `
                Recharger en journée puis compléter le soir utilise partiellement votre production solaire. 
                La recharge en soirée se fait sur le réseau dont l’électricité est issue en  majorité du pétrole, 
                réduisant les bénéfices environnementaux de votre installation solaire.`,
            class: 'warning',
            emoji: null,
        },
        {
            id: 3,
            value: 'night',
            label: 'Recharge le soir ou la nuit',
            description: `
                Recharger le soir ou la nuit ne permet pas de profiter de votre installation solaire. 
                Vous dépendez du réseau dont l’électricité est issue en majorité du pétrole,
                ce qui augmente vos coûts énergétiques et votre empreinte carbone.`,
            class: 'danger',
            emoji: null,
        },
    ];

    public chargingPointInfo = [
        {
            id: 1,
            value: 'reinforced_socket',
            label: 'Prise renforcée - 2,5 à 3\u00A0kW',
            description: `
                Recharger avec une prise renforcée optimise l'utilisation de votre installation solaire. 
                Cette recharge lente maximise l'usage de l'électricité produite en journée, tout en préservant la batterie de votre véhicule.`,
            class: 'success',
            emoji: null,
        },
        {
            id: 2,
            value: 'standard_charging_station',
            label: 'Borne de recharge standard - 7\u00A0kW',
            description: `
                Une borne de recharge standard est efficace mais peut nécessiter un appoint en énergie du réseau 
                si la recharge n'est pas synchronisée avec la production solaire. 
                C'est un bon compromis entre rapidité et valorisation de l’énergie solaire.`,
            class: 'warning',
            emoji: null,
        },
        {
            id: 3,
            value: 'fast_charging_station',
            label: 'Borne de recharge rapide - 22\u00A0kW',
            description: `
                Même en pleine journée, la borne de recharge rapide sollicite principalement le réseau électrique, 
                limitant l'usage de l'énergie solaire et réduisant les avantages écologiques et financiers de votre installation.​`,
            class: 'danger',
            emoji: null,
        },
    ];

    public chargingFrequencyInfo = [
        {
            id: 1,
            value: 5,
            label: '5 recharges par semaine',
            img: 'electric_vehicule_charging_five',
            sunsImg: 'sun_three',
            description: `
                Recharger régulièrement votre véhicule répartit mieux l'utilisation de votre production solaire.
                Ce rythme optimise l'autoconsommation photovoltaïque, réduit la dépendance au réseau électrique et diminue votre empreinte carbone.`,
            class: 'success',
            emoji: null,
        },
        {
            id: 2,
            value: 3,
            label: '3 recharges par semaine',
            img: 'electric_vehicule_charging_three',
            sunsImg: 'sun_two',
            description: `
                Recharger trois fois par semaine utilise partiellement votre production solaire.
                Ce rythme peut nécessiter un soutien du réseau électrique, augmentant légèrement votre facture d’électricité et les émissions de CO2.`,
            class: 'warning',
            emoji: null,
        },
        {
            id: 3,
            value: 1,
            label: '1 recharge par semaine',
            img: 'electric_vehicule_charging_one',
            sunsImg: 'sun_one',
            description: `
                Recharger une fois par semaine limite l'utilisation de l'énergie solaire.
                Ce rythme augmente la dépendance au réseau électrique, réduisant les avantages économiques et écologiques de votre installation solaire.​`,
            class: 'danger',
            emoji: null,
        },
    ];

    constructor(
        private readonly formBuilder: FormBuilder,
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showMobileUi = this.usefulService.showMobileUi;

        const defaultelectricVehiculeCount =
            this.solarPvSurplusService.electricMobilityData.electricVehiculeCount;
        const defaultAnnualDistance =
            this.solarPvSurplusService.electricMobilityData.annualDistance;
        const defaultChargingSchedule =
            this.solarPvSurplusService.electricMobilityData.chargingSchedule;
        const defaultChargingPoint = this.solarPvSurplusService.electricMobilityData.chargingPoint;
        const defaultChargingFrequency =
            this.solarPvSurplusService.electricMobilityData.chargingFrequency;

        this.mobiliyUsesFormGroup = this.formBuilder.group({
            electricVehiculeCount: [
                defaultelectricVehiculeCount,
                [Validators.required, Validators.min(1), Validators.max(5)],
            ],
            annualDistance: [defaultAnnualDistance, [Validators.required, Validators.min(1)]],
            chargingSchedule: [
                defaultChargingSchedule,
                [Validators.required, chargingScheduleValidator],
            ],
            chargingPoint: [defaultChargingPoint, [Validators.required, chargingPointValidator]],
            chargingFrequency: [
                defaultChargingFrequency,
                [Validators.required, chargingFrequencyValidator],
            ],
        });

        setTimeout(() => {
            this.chargingScheduleActiveTabId = this.chargingScheduleInfo.find(
                (data) => data.value == defaultChargingSchedule,
            )?.id;
            this.chargingPointActiveTabId = this.chargingPointInfo.find(
                (data) => data.value == defaultChargingPoint,
            )?.id;
            this.chargingFrequencyActiveTabId = this.chargingFrequencyInfo.find(
                (data) => data.value == defaultChargingFrequency,
            )?.id;
        }, 0);

        // await this._initMobilityUses();

        this.checkMobilityUsesFormValidity();
        this.mobiliyUsesFormGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((values) => this.checkMobilityUsesFormValidity());

        this.mobiliyUsesFormGroup
            .get('electricVehiculeCount')
            .valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (value) {
                    const annualDistanceCtrl = this.mobiliyUsesFormGroup.get('annualDistance');
                    const annualDistance =
                        this.solarPvSurplusService.electricMobilityDefaultAnnualDistance * value;
                    annualDistanceCtrl.setValue(annualDistance);
                }
            });
    }

    ngAfterViewInit() {
        this.chargingScheduleInfo[0].emoji = this.emojiSmile;
        this.chargingScheduleInfo[1].emoji = this.emojiNeutral;
        this.chargingScheduleInfo[2].emoji = this.emojiFrown;

        this.chargingPointInfo[0].emoji = this.emojiSmile;
        this.chargingPointInfo[1].emoji = this.emojiNeutral;
        this.chargingPointInfo[2].emoji = this.emojiFrown;

        this.chargingFrequencyInfo[0].emoji = this.emojiSmile;
        this.chargingFrequencyInfo[1].emoji = this.emojiNeutral;
        this.chargingFrequencyInfo[2].emoji = this.emojiFrown;
    }

    public checkMobilityUsesFormValidity() {
        this.isMobilityUsesFormValid = this.mobiliyUsesFormGroup.status === 'VALID';
    }

    private async _getDefaultMobilityUses() {
        try {
            this.defaultMobilityUses = await this.solarPvSurplusService.getDefaultMobilityUses();
            return this.defaultMobilityUses;
        } catch (error) {
            console.error('Error _getDefaultMobilityUses', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser les paramètres.",
            );
        }
    }

    openAnnualDistanceModal() {
        const title = 'Kilomètres annuels cumulés de vos véhicules';
        const body = `
            <p>
                Pour évaluer la puissance nécessaire d'une installation solaire capable d'alimenter un véhicule électrique, 
                l'hypothèse de 12&nbsp;000&nbsp;km parcourus par an est couramment utilisée.
                Ce chiffre correspond à la moyenne annuelle de kilomètres parcourus par un conducteur en Martinique.
                En partant de cette hypothèse, il est possible d'estimer la quantité d'énergie requise pour couvrir cette distance.
            </p>
            <div>
                En général, un véhicule électrique consomme environ 15&nbsp;kWh pour 100&nbsp;km et perd en moyenne 15&nbsp;% d’électricité à la recharge. 
                Ainsi, pour 12&nbsp;000&nbsp;km, l'installation solaire devra produire environ 2&nbsp;070&nbsp;kWh par an. 
                Cette estimation permet de dimensionner l'installation solaire de manière à répondre aux besoins énergétiques de vos véhicules électriques.
            </div>
        `;
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }

    setChargingScheduleTabId(chargingTimeTabId: number): void {
        this.chargingScheduleActiveTabId = chargingTimeTabId;
        const chargingScheduleCtrl = this.mobiliyUsesFormGroup.get('chargingSchedule');
        const newChargingScheduleValue = this.chargingScheduleInfo.find(
            (data) => data.id == chargingTimeTabId,
        ).value;
        chargingScheduleCtrl.setValue(newChargingScheduleValue);
    }

    setChargingPointTabId(chargingPointTabId: number): void {
        this.chargingPointActiveTabId = chargingPointTabId;
        const chargingPointCtrl = this.mobiliyUsesFormGroup.get('chargingPoint');
        const newChargingPointValue = this.chargingPointInfo.find(
            (data) => data.id == chargingPointTabId,
        ).value;
        chargingPointCtrl.setValue(newChargingPointValue);
    }

    setChargingFrequencyTabId(chargingFrequencyTabId: number): void {
        this.chargingFrequencyActiveTabId = chargingFrequencyTabId;
        const chargingFrequencyCtrl = this.mobiliyUsesFormGroup.get('chargingFrequency');
        const newChargingFrequencyValue = this.chargingFrequencyInfo.find(
            (data) => data.id == chargingFrequencyTabId,
        ).value;
        chargingFrequencyCtrl.setValue(newChargingFrequencyValue);
    }

    showChargingSchedule(): boolean {
        const chargingScheduleCtrl = this.mobiliyUsesFormGroup.get('chargingSchedule');
        return (
            !!this.chargingScheduleActiveTabId &&
            chargingScheduleCtrl.value != this.optimalChargingConfig.chargingSchedule
        );
    }

    showRechargingPoint(): boolean {
        const chargingPointCtrl = this.mobiliyUsesFormGroup.get('chargingPoint');
        return (
            !!this.chargingPointActiveTabId &&
            chargingPointCtrl.value != this.optimalChargingConfig.chargingPoint
        );
    }

    showChargingFrequency(): boolean {
        const chargingFrequencyCtrl = this.mobiliyUsesFormGroup.get('chargingFrequency');
        return (
            !!this.chargingFrequencyActiveTabId &&
            chargingFrequencyCtrl.value != this.optimalChargingConfig.chargingFrequency
        );
    }

    showWarning(): boolean {
        const showChargingSchedule = this.showChargingSchedule();
        const showRechargingPoint = this.showRechargingPoint();
        const showChargingFrequency = this.showChargingFrequency();
        return showChargingSchedule || showRechargingPoint || showChargingFrequency;
    }

    cancel() {
        this.solarPvSurplusService.updateStage('equipmentsStage');
    }

    validate() {
        const isValid = this.mobiliyUsesFormGroup.valid;
        if (isValid) {
            const electricVehiculeCountCtrl =
                this.mobiliyUsesFormGroup.get('electricVehiculeCount');
            this.solarPvSurplusService.electricMobilityData.electricVehiculeCount = Number(
                electricVehiculeCountCtrl.value,
            );

            const annualDistanceCtrl = this.mobiliyUsesFormGroup.get('annualDistance');
            this.solarPvSurplusService.electricMobilityData.annualDistance = Number(
                annualDistanceCtrl.value,
            );

            const chargingScheduleCtrl = this.mobiliyUsesFormGroup.get('chargingSchedule');
            const chargingSchedule = chargingScheduleCtrl.value;
            this.solarPvSurplusService.electricMobilityData.chargingSchedule = chargingSchedule;

            const chargingPointCtrl = this.mobiliyUsesFormGroup.get('chargingPoint');
            const chargingPoint = chargingPointCtrl.value;
            this.solarPvSurplusService.electricMobilityData.chargingPoint = chargingPoint;

            const chargingFrequencyCtrl = this.mobiliyUsesFormGroup.get('chargingFrequency');
            const chargingFrequency = chargingFrequencyCtrl.value;
            this.solarPvSurplusService.electricMobilityData.chargingFrequency = chargingFrequency;

            this.solarPvSurplusService.electricMobilityData.isOptimal = !this.showWarning();
            this.solarPvSurplusService.updateStage('consumptionStage');
        }
    }

    // private async _initMobilityUses() {
    //     this.defaultMobilityUses = await this._getDefaultMobilityUses();

    //     const electricVehiculeCount = this.usefulService.round(
    //         this.defaultMobilityUses.electricVehiculeCount,
    //     );
    //     const annualDistance = this.usefulService.ceil(this.defaultMobilityUses.annualDistance);

    //     const electricVehiculeCountCtrl = this.mobiliyUsesFormGroup.get('electricVehiculeCount');
    //     electricVehiculeCountCtrl.setValue(electricVehiculeCount);

    //     const annualDistanceCtrl = this.mobiliyUsesFormGroup.get('annualDistance');
    //     annualDistanceCtrl.setValue(annualDistance);
    // }
}
