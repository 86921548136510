<div id="cs-results" class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid solar-card">
                    <h5 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-st-primary">Mes simulations</span>
                        <stButton
                            btnClass="btn-outline-st-primary"
                            btnLabel="Supprimer cette simulation"
                            (click)="openDeleteModal()"
                        >
                        </stButton>
                    </h5>

                    <div id="simulation-buttons" class="d-flex bg-st-base00 overflow-auto">
                        <div
                            *ngFor="let result of solarService.results"
                            id="simulation-button-{{ result.id }}"
                        >
                            <a
                                target="_blank"
                                class="card-button no-text-decoration"
                                [ngClass]="{active: currentResult.id == result.id}"
                                (click)="loadCurrentResult(result)"
                            >
                                <h6 class="mb-0">Simulation {{ result.id }}&nbsp;:</h6>
                                <small>{{ result.label }}</small>
                            </a>
                        </div>

                        <div *ngIf="solarService.results.length < maxResultsCount">
                            <a
                                target="_blank"
                                class="card-button no-text-decoration add-simulation"
                                (click)="addSimulation()"
                            >
                                <!-- <i class="fa fa-plus fa-2x"></i> -->
                                <svg
                                    class="plus-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                                    />
                                </svg>
                                <small class="d-block"> Ajouter une simulation </small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <h5 class="text-st-primary">Résultats</h5>

                <cadastre-solaire-result-card
                    customClass="mb-3"
                    iconSvg="solar_panel"
                    [titleLabel]="titleInstallation"
                    [contentHtml]="currentResult.htmlInstallation"
                    [modalContentHtml]="tooltipInfoByTechnology[currentResult.technology].installationDetail"
                ></cadastre-solaire-result-card>

                <cadastre-solaire-result-card
                    customClass="mb-3"
                    iconSvg="list_check"
                    [titleLabel]="titleInvestment"
                    [contentHtml]="currentResult.htmlInvestment"
                    [modalContentHtml]="tooltipInfoByTechnology[currentResult.technology].investmentDetail"
                ></cadastre-solaire-result-card>

                <div class="mb-3">
                    <cadastre-solaire-result-card
                        customClass="rounded-bottom-0"
                        iconSvg="bolt"
                        [titleLabel]="titleProduction"
                        [contentHtml]="currentResult.htmlProduction"
                        [modalContentHtml]="tooltipInfoByTechnology[currentResult.technology].productionDetail"
                    ></cadastre-solaire-result-card>
                    <cadastreSolairePvSurplusPlot
                        *ngIf="isCurrentResultSurplus()"
                    ></cadastreSolairePvSurplusPlot>
                </div>

                <cadastre-solaire-result-card
                    icon="fa fa-eur fa-2x"
                    [titleLabel]="titleEconomy"
                    [contentHtml]="currentResult.htmlEconomy"
                    [modalContentHtml]="tooltipInfoByTechnology[currentResult.technology].economyDetail"
                ></cadastre-solaire-result-card>

                <cadastre-solaire-result-card
                    *ngIf="isElectricMobilityResultsDefined()"
                    customClass="mt-3"
                    iconSvg="electric_mobility"
                    [titleLabel]="titleElectricMobility"
                    [contentHtml]="currentResult.htmlElectricMobility"
                    [modalContentHtml]="tooltipInfoByTechnology[currentResult.technology].electricMobilityDetail"
                ></cadastre-solaire-result-card>

                <ng-container *ngIf="!!tooltipInfoByTechnology[currentResult.technology].warning">
                    <div
                        class="container-fluid mt-3"
                        [innerHtml]="tooltipInfoByTechnology[currentResult.technology].warning"
                    ></div>
                </ng-container>
                <ng-container *ngIf="!tooltipInfoByTechnology[currentResult.technology].warning">
                    <div class="text-center">
                        <small>
                            Ces données sont issues d’un traitement automatisé et fournissent un
                            premier ordre d’idée. Cela ne remplace pas la visite d'un technicien
                            agréé.
                        </small>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div
                    [ngClass]="{'col-6': modularity.downloadResults, 'col-12': !modularity.downloadResults}"
                >
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100 h-100"
                        [btnLabel]="contactButtonLabel"
                        (onClick)="openContactModal()"
                    >
                    </stButton>
                </div>
                <div class="col-6" *ngIf="modularity.downloadResults">
                    <button
                        type="button"
                        class="btn btn-st-primary solar-pdf"
                        (click)="downloadPdf()"
                        [disabled]="isResultsDownloading"
                    >
                        <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                        <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
