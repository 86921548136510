import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { NewRoof } from 'src/app/models/Solar';

import { CadastreSolaireInfoModalComponent } from '../../modals/cadastre-solaire-info-modal.component';

import { ModuleService } from 'src/app/services/module.service';
import { PlotCadastreSolaireIndicatorService } from 'src/app/services/plotIndicator/plot-cadastre-solaire.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarThermalService } from 'src/app/services/cadastre-solaire/cadastre-solaire-thermal.service';
import { UsefulService } from 'src/app/services/UsefulService';

@UntilDestroy()
@Component({
    selector: 'cadastreSolaireNewRoof',
    templateUrl: './cadastre-solaire-new-roof.template.html',
    styleUrls: ['./cadastre-solaire-new-roof.component.scss'],
})
export class CadastreSolaireNewRoofComponent implements OnInit {
    public isZoneConstraint: boolean = false;
    private newElement: NewRoof;

    public installationTypeOptions = [
        {
            value: 'roofsection',
            label: 'Pan de toit',
        },
        {
            value: 'rooftop',
            label: 'Toit-terrasse',
        },
        {
            value: 'shade',
            label: 'Ombrière',
        },
        {
            value: 'ground',
            label: 'Installation au sol',
        },
        {
            value: 'other',
            label: 'Autre',
        },
    ];

    public orientationOptions = [
        {
            value: 'north',
            label: 'Nord',
        },
        {
            value: 'north-est',
            label: 'Nord-Est',
        },
        {
            value: 'est',
            label: 'Est',
        },
        {
            value: 'south-est',
            label: 'Sud-Est',
        },
        {
            value: 'south',
            label: 'Sud',
        },
        {
            value: 'south-west',
            label: 'Sud-Ouest',
        },
        {
            value: 'west',
            label: 'Ouest',
        },
        {
            value: 'north-west',
            label: 'Nord-Ouest',
        },
    ];

    public newRoofFormGroup: FormGroup;
    public isFormValid: boolean = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private modalService: NgbModal,
        @Inject(ModuleService) public moduleService: ModuleService,
        private notification: ToastrService,
        @Inject(PlotCadastreSolaireIndicatorService)
        private plotCadastreSolaireIndicatorService: PlotCadastreSolaireIndicatorService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarThermalService) public solarThermalService: SolarThermalService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        @Inject(SolarPvSurplusService) private solarPvSurplusService: SolarPvSurplusService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.isZoneConstraint = this.solarService.isZoneConstraint;
        this._initSelectionData();
        await this._initFormGroup();

        this._listenToEvents();
    }

    private _listenToEvents() {
        this.solarService.newElementObs$
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(async (newElement) => {
                if (newElement) {
                    this.isZoneConstraint = this.solarService.isZoneConstraint;
                    this._initSelectionData();
                    await this._initFormGroup();
                }
            });
    }

    private async _initFormGroup(): Promise<void> {
        this.newRoofFormGroup = null;

        this.newElement = this.solarService.selectedElements.find((element) => element.isNew);
        const defaultInstallationType = this.newElement.installationType
            ? this.newElement.installationType
            : 'roofsection';
        const defaultOrientation = this.newElement.orientation
            ? this.newElement.orientation
            : 'south';
        const defaultTilt = this.newElement.tilt ? this.newElement.tilt : undefined;
        const defaultUsableRoofArea = this.newElement.usableRoofArea
            ? this.newElement.usableRoofArea
            : undefined;
        const defaultheight = this.newElement.height ? this.newElement.height : undefined;

        await new Promise((resolve) => setTimeout(resolve, 0));

        this.newRoofFormGroup = this.formBuilder.group({
            installationType: [defaultInstallationType, Validators.required],
            orientation: [defaultOrientation, Validators.required],
            tilt: [defaultTilt, [Validators.required, Validators.min(0)]],
            usableRoofArea: [defaultUsableRoofArea, [Validators.required, Validators.min(1)]],
            height: [defaultheight, [Validators.required, Validators.min(1)]],
        });

        this.checkFormValidity();
        this.newRoofFormGroup.valueChanges.subscribe((values) => this.checkFormValidity());
    }

    openHeightModal() {
        const title = 'Hauteur de l’installation';
        const body = `
            <p>
                Définir la hauteur de l’installation permet de réaliser des calculs d’ombrage solaire.
                Ceux-ci visent à prédire les zones d'ombre créées par les éléments environnants
                (bâtiments alentours, arbres, montagnes, etc.) sur votre installation.
                Ainsi, ces calculs permettent de concevoir votre installation de manière optimale
                et de maximiser l'énergie qu’elle produira.
            </p>
            <div>
                Pour vous aider à estimer la hauteur
                <span class="fw-bold">approximative</span>
                de votre installation, voici quelques valeurs standards par étage et par types de construction :
                <ul class="mb-0">
                    <li>Maison individuelle neuve : 2,6 m</li>
                    <li>Immeuble d’habitation récent, hôtel ou parking : 2,6 m</li>
                    <li>Immeuble d’habitation ancienne : 3 m</li>
                    <li>Immeuble de bureaux ou hôpital : 3,3 m</li>
                    <li>Magasin, grande surface, gare ou bâtiment d’exposition : 4 m</li>
                </ul>
            </div>
        `;
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }

    async startSimulation() {
        await this._getCustomTerritoryInfo();

        const isValid = this.newRoofFormGroup.valid;
        if (isValid) {
            const values = this.newRoofFormGroup.value as NewRoof;

            values.usableRoofArea = Number(values.usableRoofArea);
            values.height = Number(values.height);
            values.tilt = Number(values.tilt);
            values.azimuth = this.solarService.convertOrientation(values.orientation);
            this.solarService.setNewElementParameters(this.newElement, values);

            this._setSelectionInfo(values);
            this.solarPvSurplusService.equipmentsData.occupantCount =
                this.solarPvSurplusService.setDefaultOccupantCount();

            const floorCount = this.usefulService.round(values.height / 3);
            this.solarPvSurplusService.equipmentsData.usableFloorArea =
                values.usableRoofArea * floorCount;

            this.solarService.updateMainStage('technologyStage');
        }
    }

    private _setSelectionInfo(values: NewRoof) {
        const installation = this.installationTypeOptions.find(
            (installation) => installation.value == values.installationType,
        );
        const installationLabel =
            installation.value == 'other' ? 'installation' : installation.label.toLocaleLowerCase();
        const myRoofLabel = `Votre ${installationLabel} peut recevoir jusqu'à`;

        const stringifiedTotalGrossRoofArea = this.usefulService.stringifyNumber(
            values.usableRoofArea,
        );

        const orientation = this.orientationOptions.find(
            (orientation) => orientation.value == values.orientation,
        );
        const orientationLabel = orientation.label;

        const directionLabel = `de surface principalement orientée ${orientationLabel},`;
        const stringifiedTilt = this.usefulService.stringifyNumber(values.tilt);
        const tiltLabel = `inclinée à ${stringifiedTilt}°.`;

        this.solarService.initSelectionInfo();
        this.solarService.selectionInfo.myRoof = myRoofLabel;
        this.solarService.selectionInfo.totalModuleArea = '';
        this.solarService.selectionInfo.totalGrossRoofArea = stringifiedTotalGrossRoofArea;
        this.solarService.selectionInfo.direction = directionLabel;
        this.solarService.selectionInfo.tilt = tiltLabel;
    }

    goToResultsStage() {
        this.solarService.updateMainStage('resultsStage');
    }

    public checkFormValidity(): void {
        this.isFormValid = this.newRoofFormGroup.status === 'VALID';
    }

    private async _getCustomTerritoryInfo() {
        const isDifferentTerritory = this.solarService.isDifferentTerritory();
        if (!isDifferentTerritory) {
            return;
        }

        try {
            const customInfo = await this.solarService.getSolarCustomTerritoryInfo();
            this._setCustomInfo(customInfo);
        } catch (error) {
            console.error('Error _getCustomTerritoryInfo', error);
            this.solarService.selectionInfo.showLoader = false;
            this.solarService.customInfo = null;
            this.plotCadastreSolaireIndicatorService.selectedExistingElementsGroup.clearLayers();
            this.plotCadastreSolaireIndicatorService.selectedNewElementsGroup.clearLayers();
            this.solarService.updateMainStage(null);
            this.moduleService.closeModule('solar');
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser les paramètres.",
            );
            throw error;
        }
    }

    private _setCustomInfo(customInfo: any) {
        this.solarService.customInfo = customInfo;

        this.solarService.moduleInfo = customInfo.module;
        this.solarPvTotalityService.setPvInfo(customInfo.pv);
        this.solarPvSurplusService.setPvInfo(customInfo.pv);
        this.solarThermalService.setThInfo(customInfo.th);
    }

    private _initSelectionData() {
        this.solarService.initResults();
        this.solarPvSurplusService.initTypologyData();
        this.solarPvSurplusService.initEquipmentsData();
        this.solarPvSurplusService.initElectricMobilityData();
        this.solarPvSurplusService.initConsumptionData();
        this.solarPvSurplusService.initImportedElectricLoad();
        this.solarPvSurplusService.initEquipments();
    }

    // onInstallationTypeChange(): void {
    //     const installationTypeCtrl = this.newRoofFormGroup.get('installationType');
    //     installationTypeCtrl!.valueChanges.subscribe((value) =>
    //         this.getNewValueBasedOnInstallationType(value),
    //     );
    // }

    // getNewValueBasedOnInstallationType(installationType: string) {
    //     const tiltCtrl = this.newRoofFormGroup.get('tilt');
    //     const heightCtrl = this.newRoofFormGroup.get('height');
    //     const orientationCtrl = this.newRoofFormGroup.get('orientation');

    //     switch (installationType) {
    //         case 'roofsection':
    //             tiltCtrl.patchValue(undefined);
    //             heightCtrl.patchValue(6);
    //             break;
    //         case 'rooftop':
    //             tiltCtrl.patchValue(11);
    //             heightCtrl.patchValue(undefined);
    //             break;
    //         case 'shade':
    //             tiltCtrl.patchValue(undefined);
    //             heightCtrl.patchValue(5);
    //             break;
    //         case 'ground':
    //             tiltCtrl.patchValue(10);
    //             heightCtrl.patchValue(1);
    //             orientationCtrl.patchValue('south');
    //             break;
    //         default:
    //             heightCtrl.patchValue(undefined);
    //             tiltCtrl.patchValue(undefined);
    //             break;
    //     }
    // }
}
