import { Component, Inject, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cadastreSolaireInfoModal',
    templateUrl: './cadastre-solaire-info-modal.template.html',
})
export class CadastreSolaireInfoModalComponent implements OnInit {
    htmlContact: any;
    @Input() body = '';
    @Input() title = '';

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.onOpen();
    }

    onOpen() {}

    close() {
        this.activeModal.close();
    }
}
