import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as turf from '@turf/turf';
import * as L from 'leaflet';
import '../libs/leaflet.PolylineMeasure';
import 'leaflet-easybutton';
import 'leaflet-draw';

import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { RestService } from 'src/app/services/RestService';
import { UsefulService } from 'src/app/services/UsefulService';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class MapService {
    public mapObs = new BehaviorSubject<any>(null);
    public map$: Observable<any> = this.mapObs.asObservable();

    public isMarkerControlActiveObs = new BehaviorSubject<boolean>(false);
    public isMarkerControlActiveObs$: Observable<boolean> =
        this.isMarkerControlActiveObs.asObservable();

    private initLat: number = 48.85999616743707;
    private initLng: number = 2.33999978951907;
    private initZoom: number = 2;
    private initZoomDelta: number = 0.25;
    private initZoomSnap: number = 0.25;
    private initWheelPxPerZoomLevel: number = 120; // leaflet default value is 60

    private activeTileLayer: L.TileLayer;
    private isAddMarkerActivated: boolean;

    public zoomControl: L.Control;
    public scaleControl: L.Control;
    public rulerControl: L.Control;
    public markerControl: L.Control;

    // private addressMarkerLayer: L.Marker;
    public markerLayer: L.Marker;
    private iconMarker: L.DivIcon;

    private drawLock: boolean = false;
    private mapElementId: string = 'map';

    // private flatFeatureGroup: L.FeatureGroup = L.featureGroup();
    // private lineFeatureGroup: L.FeatureGroup = L.featureGroup();
    // private circleFeatureGroup: L.FeatureGroup = L.featureGroup();

    public map: L.Map;
    public rasters: any;
    public currentRaster: any;

    public drawPolyLineHandler: any;
    public drawPointHandler: any;

    private defaultMinZoom: number;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(RestService) private restService: RestService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.iconMarker = L.divIcon({
            popupAnchor: [2, -25],
            className: 'custom-div-icon',
            html: `
                <div class='marker-pin'></div>
                <i class='fa fa-plus awesome'></i>
            `,
        });
    }

    clear() {
        if (this.map) {
            this.map.clearAllEventListeners();
            this.map.remove();
        }
        this._clearObservables();
    }

    private _clearObservables() {
        this.mapObs.next(null);
        this.isMarkerControlActiveObs.next(false);
    }

    createMap() {
        this.map = L.map(this.mapElementId, {
            zoomDelta: this.initZoomDelta,
            zoomSnap: this.initZoomSnap,
            wheelPxPerZoomLevel: this.initWheelPxPerZoomLevel,
            zoomControl: false,
        });
        this.map.setView(L.latLng(this.initLat, this.initLng), this.initZoom);
        this.defaultMinZoom = this.map.getMinZoom();
        this.mapObs.next(this.map);
    }

    setMinZoom(minZoom: number) {
        this.map.setMinZoom(minZoom);
    }

    setDefaultMinZoom() {
        this.map.setMinZoom(this.defaultMinZoom);
    }

    async initMapComponents() {
        this.setMainControls();
        this.setMainMapEventListeners();
        await this.setRaster();
    }

    async setRaster() {
        try {
            await this.loadRasters();
            const profileName = environment.name;

            let saveRasterId: number;
            if (profileName == 'siterre') {
                saveRasterId = this.localStorageService.get('rasterId');
            }

            let defaultRasterId = 4;
            const preferences = this.localStorageService.get('preferences');
            if (preferences) {
                defaultRasterId = preferences.init.rasterId || 4;
            }

            const rasterId = saveRasterId ? saveRasterId : defaultRasterId;
            this.updateRaster(rasterId);
            return;
        } catch (error) {
            console.error('Error createMap', error);
            throw error;
        }
    }

    async loadRasters() {
        try {
            const rasters = await this.restService.getGeoRasters();
            this.rasters = this.usefulService.sortAlphabetically(rasters, 'name');
            return;
        } catch (error) {
            console.error('Error loadRaster', error);
            throw error;
        }
    }

    updateRaster(rasterId: number) {
        if (this.activeTileLayer) {
            this.map.removeLayer(this.activeTileLayer);
        }

        const raster = this.rasters.find((raster: any) => raster.id == rasterId);
        if (raster) {
            this.currentRaster = raster;

            const url = raster.url;
            const attribution = `${raster.attribution} | <a href="${environment.linkMap}" target="_blank">${environment.labelMap}</a>`;

            this.activeTileLayer = L.tileLayer(url, {
                attribution: attribution,
                maxZoom: 23,
                maxNativeZoom: 19,
            });
            this.activeTileLayer.addTo(this.map);

            const profileName = environment.name;
            if (profileName == 'siterre') {
                this.localStorageService.set('rasterId', rasterId);
            }
        }
    }

    removeMainControls() {
        this.map.removeControl(this.zoomControl);
        this.map.removeControl(this.scaleControl);
        this.map.removeControl(this.rulerControl);
        this.map.removeControl(this.markerControl);
    }

    setMainControls() {
        this.setScaleControl();
        this.setZoomControl();
        this.setRulerControl();
        this.setMarkerControl();
    }

    setZoomControl() {
        this.zoomControl = L.control.zoom({ position: 'bottomright' });
        this.zoomControl.addTo(this.map);
    }

    setScaleControl() {
        this.scaleControl = L.control.scale({ position: 'bottomright', imperial: false });
        this.scaleControl.addTo(this.map);
    }

    setRulerControl() {
        this.rulerControl = (L.control as any).polylineMeasure({
            position: 'bottomright',
            measureControlTitleOn: 'Mesurer une distance',
            measureControlTitleOff: 'Retirer les mesures',
        });
        this.rulerControl.addTo(this.map);
    }

    setMarkerControl() {
        this.markerControl = L.easyButton({
            position: 'bottomright',
            id: 'leaflet-add-marker-btn',
            states: [
                {
                    stateName: 'active',
                    icon: 'fa-map-marker',
                    title: 'Ajouter un marqueur',
                    onClick: (control: any) => {
                        this.isAddMarkerActivated = true;
                        this.isMarkerControlActiveObs.next(true);
                        this.map.on('click', this._handleMarkerOnMap);
                        control.state('lock');
                    },
                },
                {
                    icon: 'fa-lock',
                    stateName: 'lock',
                    onClick: (control: any) => {
                        this.isAddMarkerActivated = false;
                        this.isMarkerControlActiveObs.next(false);
                        this.map.off('click', this._handleMarkerOnMap);
                        control.state('active');
                    },
                    title: 'Verrouiller le marqueur',
                },
            ],
        });
        this.markerControl.addTo(this.map);
    }

    private _handleMarkerOnMap = (e: any) => {
        const lat = e.latlng.lat;
        const lng = e.latlng.lng;

        if (this.isAddMarkerActivated) {
            const currentLatLng = this.markerLayer
                ? this.markerLayer.getLatLng()
                : ({} as { lat: number; lng: number });
            this.removeMarker();

            this.markerLayer = L.marker([lat, lng], { icon: this.iconMarker });

            if (lat != currentLatLng.lat && lng != currentLatLng.lng) {
                this.markerLayer.addTo(this.map);
            }
        }
    };

    setMainMapEventListeners() {
        this.setClickEvent();
        this.setDragEvent();
    }

    setClickEvent() {
        this.map.on('click', this._handleCloseAllPopup);
    }

    private _handleCloseAllPopup = (e: any) => this.closeAllPopup();

    setDragEvent() {
        const dragenter = (e: any) => {
            e.stopPropagation();
            e.preventDefault();
        };

        const dragover = (e: any) => {
            e.stopPropagation();
            e.preventDefault();
        };

        const mapElement = document.getElementById(this.mapElementId);
        mapElement.addEventListener('dragenter', dragenter, false);
        mapElement.addEventListener('dragover', dragover, false);
        mapElement.addEventListener(
            'dragleave',
            () => {
                this.map.scrollWheelZoom.enable();
            },
            false,
        );
    }

    // addLayers(layers: L.Layer[]) {
    //     layers.forEach((layer) => this.addLayer(layer));
    //     this.sortLayers();
    // }

    // addLayer(layer: L.Layer) {
    // if (layer instanceof L.Polygon) {
    //     this.flatFeatureGroup.addLayer(layer);
    // }
    // if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
    //     this.lineFeatureGroup.addLayer(layer);
    // }
    // if (layer instanceof L.CircleMarker) {
    //     this.circleFeatureGroup.addLayer(layer);
    // }
    // }

    // removeLayers(layers: L.Layer[]) {
    //     layers.forEach((layer) => this.removeLayer(layer));
    // }

    // removeLayer(layer: L.Layer) {
    //     if (layer instanceof L.Polygon) {
    //         this.flatFeatureGroup.removeLayer(layer);
    //     }
    //     if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
    //         this.lineFeatureGroup.removeLayer(layer);
    //     }
    //     if (layer instanceof L.CircleMarker) {
    //         this.circleFeatureGroup.removeLayer(layer);
    //     }
    // }

    activateDropEvent(callback: any) {
        const mapElement = document.getElementById(this.mapElementId);
        mapElement.addEventListener('drop', callback, false);
    }

    deactivateDropEvent(callback: any) {
        const mapElement = document.getElementById(this.mapElementId);
        mapElement.removeEventListener('drop', callback, false);
    }

    activateMoveEventOnce(callback: any) {
        this.map.once('moveend', callback);
    }

    activateMoveEvent(callback: any) {
        // this.map.on('moveend', callback);

        const debounceTimeMs = environment.production ? 500 : 1000;
        const subscription = fromEvent(this.map, 'moveend')
            .pipe(
                debounceTime(debounceTimeMs),
                map(() => callback()),
                untilDestroyed(this),
            )
            .subscribe();
        return subscription;
    }

    deactivateMoveEvent(callback: any) {
        this.map.off('moveend', callback);
    }

    activateZoomEndEvent(callback: any) {
        this.map.on('zoomend', callback);
    }

    deactivateZoomEndEvent(callback?: any) {
        this.map.off('zoomend', callback);
    }

    enableDrawPoint = (callBack: any) => {
        if (this.drawLock) {
            return;
        }
        this.drawLock = true;
        this.drawPointHandler = new (L as any).Draw.Marker(this.map);
        this.drawPointHandler.enable();
        this.map.once('draw:created', (e: any) => {
            this.drawPointHandler.disable();

            this.drawLock = false;
            callBack(e.layer.toGeoJSON());
        });
        // this.map.once('draw:editstop', () => {
        //     this.drawPointHandler.disable();
        //     this.drawLock = false;
        // });
        // this.map.once('draw:canceled', () => {
        //     this.drawPointHandler.disable();
        //     this.drawLock = false;
        // });
    };

    disableDrawPoint() {
        if (this.drawPointHandler && this.drawPointHandler.enabled()) {
            this.drawPointHandler.disable();
            this.drawLock = false;
        }
    }

    enableDrawPolyLine(createdCallBack: any, editstopCallback?: any, canceledCallback?: any) {
        if (this.drawLock) {
            return;
        }
        this.drawLock = true;
        this.drawPolyLineHandler = new (L as any).Draw.Polyline(this.map);
        this.drawPolyLineHandler.enable();
        this.map.once('draw:created', (e: any) => {
            this.drawPolyLineHandler.disable();
            this.drawLock = false;

            createdCallBack(e.layer.toGeoJSON());
        });
        // this.map.once('draw:editstop', () => {
        //     this.drawPolyLineHandler.disable();
        //     this.drawLock = false;

        //     if (editstopCallback) {
        //         editstopCallback();
        //     }
        // });
        // this.map.once('draw:canceled', () => {
        //     console.log('draw canceled');
        //     this.drawPolyLineHandler.disable();
        //     this.drawLock = false;

        //     if (canceledCallback) {
        //         canceledCallback();
        //     }
        // });
    }

    disableDrawPolyLine() {
        if (this.drawPolyLineHandler && this.drawPolyLineHandler.enabled()) {
            this.drawPolyLineHandler.disable();
            this.drawLock = false;
        }
    }

    getCenter() {
        return this.map.getCenter();
    }

    centerOn(lat: number, lng: number, zoom: number, animate: boolean, callback?: any) {
        if (callback) {
            this.activateZoomEndEvent(callback);
        }

        const latLng = L.latLng(lat, lng);
        if (animate) {
            this.map.flyTo(latLng, zoom);
        } else {
            this.setView(latLng, zoom);

            // const isLeftPanelOpen = $('#left-panel').width() > 0;
            // if (isLeftPanelOpen) {
            //     const shiftedLatLng = this.getShiftedLatLng(latLng);
            //     this.setView(shiftedLatLng, zoom);
            // }
        }
    }

    async centerOnBbox(
        year: number,
        scaleTypeId: number,
        territoryIds: string[],
        zoomEventCallback?: any,
    ) {
        if (zoomEventCallback) {
            this.activateZoomEndEvent(zoomEventCallback);
        }

        try {
            const parameters = {
                year: year,
                scaleTypeId: scaleTypeId,
                territoryIds: JSON.stringify(territoryIds),
            };
            const geojson = await this.restService.getGeoBbox(parameters);
            this.map.flyToBounds(L.geoJson(geojson).getBounds());
            return;
        } catch (error) {
            console.error('Error recentrerOnBox', error);
            throw error;
        }
    }

    setView(latLng: L.LatLng, zoom?: number) {
        zoom = zoom ? zoom : this.map.getZoom();
        this.map.setView(latLng, zoom);
    }

    getShiftedLatLng(latLng: L.LatLng, shiftPoint?: L.Point) {
        if (!shiftPoint) {
            const leftPanelWidth = $('#left-panel').width();
            shiftPoint = L.point(leftPanelWidth / 2, 0);
        }

        const containerPoint = this.convertLatLngToContainerPoint(latLng);

        containerPoint.x -= shiftPoint.x;
        containerPoint.y -= shiftPoint.y;

        const shiftedLatLng = this.convertContainerPointToLatLng(containerPoint);

        return shiftedLatLng;
    }

    convertLatLngToContainerPoint(latLng: L.LatLng) {
        const point = this.map.latLngToLayerPoint(latLng);
        const containerPoint = this.map.layerPointToContainerPoint(point);
        return containerPoint;
    }

    convertContainerPointToLatLng(containerPoint: L.Point) {
        const point = this.map.containerPointToLayerPoint(containerPoint);
        const latLng = this.map.layerPointToLatLng(point);
        return latLng;
    }

    addMarkerAddress(lat: number, lng: number) {
        this.removeMarker();
        this.markerLayer = L.marker([lat, lng], { icon: this.iconMarker });
        this.markerLayer.addTo(this.map);
        // this.markerLayer = this.addressMarkerLayer;
    }

    removeMarker() {
        const hasLayer = this.map.hasLayer(this.markerLayer);
        if (hasLayer) {
            this.map.removeLayer(this.markerLayer);
            this.markerLayer = undefined;
        }
    }

    closeAllTootip() {
        this.map.eachLayer((layer: L.Layer) => layer.closeTooltip().unbindTooltip());
    }

    closeAllPopup() {
        this.map.eachLayer((layer: L.Layer) => {
            const feature = (layer as any).feature;
            if (feature) {
                const isPopupPinOn = feature.properties.isPopupPinOn;
                if (!isPopupPinOn) {
                    layer.closePopup();
                    feature.properties.isPopupClickOn = false;
                    feature.properties.isPopupPinOn = false;
                }
            }
        });
    }

    distance(from: any, to: number[]) {
        return turf.distance(turf.point(from), turf.point(to)) * 1000;
    }

    convertPointToPolygon(centerPoint: number[]) {
        const poly = turf.circle(centerPoint, 0.002, {
            steps: 4,
            units: 'kilometers',
        });
        return poly.geometry.coordinates;
    }

    lineSliceAlong(lineCoord: number[][], distance: number, start: number = 0) {
        const line = turf.lineString(lineCoord);
        const sliced = turf.lineSliceAlong(line, start / 1000, distance / 1000, {
            units: 'kilometers',
        });
        return sliced.geometry.coordinates[1];
    }

    zoomToFeature(feature: GeoJSON.Feature) {
        this.map.flyToBounds(L.geoJson(feature).getBounds());
    }

    highlightLayer(layer: any) {
        layer.defaultStyle = {
            color: layer.options.color,
            opacity: layer.options.opacity,
            weight: layer.options.weight,
        };

        layer.setStyle({
            weight: 15,
            color: 'white',
            opacity: 1,
        });
    }

    convertMultiPointToPoint = (geojson: GeoJSON.FeatureCollection, inplace = false) => {
        let actualGeojson = !inplace ? { ...geojson } : geojson;

        // For some reason we have some features whith empty geometry, so we filter this out...
        actualGeojson.features = actualGeojson.features.filter(
            (feature: GeoJSON.Feature) => feature.geometry,
        );

        actualGeojson.features
            .filter((feature: GeoJSON.Feature) => feature.geometry.type == 'MultiPoint')
            .forEach((feature: any) => {
                feature.geometry.type = 'Point';
                feature.geometry.coordinates = feature.geometry.coordinates[0];
            });

        return actualGeojson;
    };

    // sortLayers() {
    //     this.flatFeatureGroup.bringToFront();
    //     this.lineFeatureGroup.bringToFront();
    //     this.circleFeatureGroup.bringToFront();
    // }

    flyToBounds(bounds: L.Bounds, isShifted: boolean = false) {
        // we have to use timeout() because of reasons...
        // javascript has trouble to access to element width otherwise
        setTimeout(() => {
            // const options: any = {};
            // if (isShifted) {
            //     const leftPanelWidth = $('#left-panel').width();
            //     options.paddingBottomRight = L.point([leftPanelWidth, 0]);
            // }
            this.map.flyToBounds(bounds as any);
        }, 0);
    }

    invalidateSize() {
        if (this.map) {
            setTimeout(() => this.map.invalidateSize(), 0);
        }
    }

    checkIfPointIsWithinPolygon(latlng: L.LatLng, polygonLayer: L.Layer): boolean {
        const pointFeature = turf.point([latlng.lng, latlng.lat]);
        const geojson = (polygonLayer as L.GeoJSON).toGeoJSON();
        const coordinates = (geojson as any).features[0].geometry.coordinates;

        const multiLineString = turf.multiLineString(coordinates);
        const polygonFeature = turf.lineToPolygon(multiLineString);
        const isWithinPolygon = turf.booleanWithin(pointFeature, polygonFeature);

        return isWithinPolygon;
    }

    checkIfPointIsWithinMultiPolygon(latlng: L.LatLng, polygonLayer: L.Layer): boolean {
        const pointFeature = turf.point([latlng.lng, latlng.lat]);
        const geojson = (polygonLayer as L.GeoJSON).toGeoJSON();
        const coordinates = (geojson as any).features[0].geometry.coordinates;

        const isPointWithinPolygon = coordinates.reduce(
            (isPointWithinPolygon: boolean, polygonCoordinates: number[][]) => {
                const polygonFeature = turf.polygon([polygonCoordinates]);
                const isWithinPolygon = turf.booleanWithin(pointFeature, polygonFeature);
                const isPointWithinTerritory = isWithinPolygon || isPointWithinPolygon;
                return isPointWithinTerritory;
            },
            false,
        );
        return isPointWithinPolygon;
    }

    waitForZoomEnd(): Promise<void> {
        return new Promise((resolve) => this.map.once('zoomend', () => resolve()));
    }

    // panMapBy(layer: L.Layer) {
    //     // we have to use timeout() because of reasons...
    //     // javascript has trouble to access to element width otherwise
    //     setTimeout(() => {
    //         let rightPanelWidth = $('#right-panel').width();
    //         const isRightPanelOpen = rightPanelWidth > 0;

    //         if (isRightPanelOpen) {
    //             const popupElement = layer.getPopup();
    //             const wrapper = popupElement.getElement();

    //             const latLng = popupElement.getLatLng();
    //             const containerPoint = this.convertLatLngToContainerPoint(latLng);

    //             // const layerBounds = layer.getBounds();
    //             // const layerSouthEastLatLng = layerBounds.getNorthEast();
    //             // const layerSouthEastPoint =
    //             //     this.convertLatLngToContainerPoint(layerSouthEastLatLng);
    //             // const layerRightEndX = layerSouthEastPoint.x;

    //             const popupWidth = wrapper.offsetWidth;
    //             const popupRightEndX = containerPoint.x + popupWidth / 2;

    //             // 13 px because of :
    //             // $('.leaflet-popup-tip-container').height / 2 = 10px
    //             // padding top = 1px
    //             // padding bottom = 1px
    //             // and 1px for overlap
    //             const popupHeight = wrapper.offsetHeight + 13;
    //             const popupTopEndY = containerPoint.y - popupHeight;

    //             const iconsColumnHeight = $('#icon-nav-container').height();
    //             if (popupTopEndY < iconsColumnHeight) {
    //                 const iconsColumnWidth = $('#icon-nav-container').width();
    //                 rightPanelWidth += iconsColumnWidth;
    //             }

    //             const mapSize = this.map.getSize();
    //             const rightPanelLeftEndX = mapSize.x - rightPanelWidth;

    //             // const rightEndX = Math.max(layerRightEndX, popupRightEndX);
    //             const rightEndX = popupRightEndX;
    //             const gap = rightEndX - rightPanelLeftEndX;
    //             const xShift = Math.max(gap + 5, 0);

    //             if (xShift) {
    //                 const yShift = Math.min(popupTopEndY - 5, 0);
    //                 const shiftPoint = L.point(xShift, yShift);
    //                 this.map.panBy(shiftPoint);
    //             }
    //         }
    //     }, 0);
    // }
}
