import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';

import { PrParameter } from '../models/PrTypes';

import { UsefulService } from './UsefulService';

@Injectable({
    providedIn: 'root',
})
export class RestService {
    private backEndUrl = environment.backEndUrl;
    private geoBackEndUrl = environment.geoBackEndUrl;
    private criticError = "Une erreur est survenue. Impossible de charger l'application.";

    constructor(
        private http: HttpClient,
        private notification: ToastrService,
        private router: Router,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    //##################################### Fonctions internes ###########################################################

    // Auxiliary functions to simplify the notation, one for each method and api
    getBackEnd(path: string, parameters?: any, notification?: string) {
        return this.send('GET', false, path, parameters, null, notification);
    }
    getGeoBackEnd(path: string, parameters?: any, notification?: string) {
        return this.send('GET', true, path, parameters, null, notification);
    }
    postBackEnd(path: string, parameters: any, data: any, notification?: string) {
        return this.send('POST', false, path, parameters, data, notification);
    }
    postGeoBackEnd(path: string, parameters: any, data: any, notification?: string) {
        return this.send('POST', true, path, parameters, data, notification);
    }
    putBackEnd(path: string, data: any, notification?: string) {
        return this.send('PUT', false, path, null, data, notification);
    }
    deleteBackEnd(path: string, parameters?: any, notification?: string) {
        return this.send('DELETE', false, path, parameters, null, notification);
    }

    // Main function used everywhere to send requests to the two back-ends
    async send(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        isGeoBackEnd: boolean,
        path: string,
        parameters: any,
        data: any,
        notification?: string,
    ) {
        const apiUrl = isGeoBackEnd ? this.geoBackEndUrl : this.backEndUrl;

        let promise: Observable<any>;
        if (method === 'GET') {
            const params = this.setParams(parameters);
            promise = this.http.get(apiUrl + path + params);
        } else if (method === 'DELETE') {
            promise = this.http.delete(apiUrl + path);
        } else if (method === 'PUT') {
            promise = this.http.put(apiUrl + path, data);
        } else {
            const params = this.setParams(parameters);
            promise = this.http.post(apiUrl + path + params, data);
        }

        try {
            const result = await lastValueFrom(promise);
            return result;
        } catch (error) {
            console.error(error);
            // Here we should probably have a more complete error handling system with adapted messages
            const statusCode = error.status;
            if (statusCode) {
                switch (statusCode) {
                    case 403:
                        this.notification.error('Connexion impossible.');
                        this.router.navigate(['/']);
                        break;
                    // case '23505': // or any other error status code
                    //     this.notification.error(
                    //         "Cet identifiant existe déjà, merci d'en choisir un autre.",
                    //     );
                    //     break;
                }
            }

            if (notification) {
                this.notification.error(notification);
            }
            throw error;
        }
    }

    setParams(parameters: any) {
        const arr = [];
        for (let key in parameters) {
            let value = parameters[key];
            arr.push(`${key}=${value}`);
        }
        const params = arr.length ? '?' + arr.join('&') : '';
        return params;
    }

    //################################ Connexion / déconnexion  #######################################################

    //==================== LogIn =======================================================================================
    logIn(credentials: { login: string; password: string }) {
        return this.postBackEnd('/signin', null, credentials);
    }

    externalLogIn(group: string, token: string) {
        const path = `/signin/${group}/${token}`;
        return this.postBackEnd(path, null, null);
    }

    //################################ Preference ############################################################################

    getUserPreference(parameters: { addUserInfo: boolean }) {
        const path = '/modularities/user';
        return this.getBackEnd(path, parameters);
    }

    //################################ Library Indicators ####################################################################

    createIndicator(data: any) {
        const path = `/library/indicators`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    getIndicator(indicatorId: number, parameters?: any) {
        const path = `/library/indicators/${indicatorId}/`;
        return this.getBackEnd(path, parameters);
    }

    updateIndicator(indicatorId: number, data: any) {
        const path = `/library/indicators/${indicatorId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    deleteIndicator(indicatorId: number) {
        const path = `/library/indicators/${indicatorId}`;
        return this.deleteBackEnd(path, null, this.criticError);
    }

    createIndicatorCustomization(data: any) {
        const path = `/library/indicators-customization`;
        return this.postBackEnd(path, null, data);
    }

    updateIndicatorCustomization(data: any) {
        const path = `/library/indicators-customization`;
        return this.putBackEnd(path, data);
    }

    //################################ Indicators ####################################################################

    getIndicatorByModule(parameters?: { groupId: string }) {
        const path = `/indicators/modules`;
        return this.getBackEnd(path, parameters);
    }

    //################################ Indicator ############################################

    getIndicatorValues(indicatorId: number, data: any) {
        const path = `/indicator/${indicatorId}/values`;
        return this.postBackEnd(path, null, data);
    }

    getIndicatorAggregation(indicatorId: number, data: any) {
        const path = `/indicator/${indicatorId}/aggregation`;
        return this.postBackEnd(path, null, data);
    }

    getCurrentIndicatorAggregation(indicatorId: number, data: any) {
        const path = `/indicator/${indicatorId}/current-aggregation`;
        return this.postBackEnd(path, null, data);
    }

    getIndicatorFilters(indicatorId: number, data: any) {
        const path = `/indicator/${indicatorId}/filters`;
        return this.postBackEnd(path, null, data);
    }

    getIndicatorFiltersValue(indicatorId: number, data: any) {
        const path = `/indicator/${indicatorId}/filters-value`;
        return this.postBackEnd(path, null, data);
    }

    // getIndicatorsValues(indicatorId: number, query: any) {
    //     const params = this.usefulService.encodeForUrl(query);
    //     const path = `/indicator/${indicatorId}/singleter?${params}`;
    //     return this.getBackEnd(path);
    // }

    // getIndicatorMean(indicatorId: number, parameters: any) {
    //     const path = `/indicator/${indicatorId}/mean`;
    //     return this.getBackEnd(path, parameters);
    // }

    //################################ Indicators with custom territory ################################################

    getCustomTerritoryIndicatorGeojson(indicatorId: number, data: any) {
        const path = `/indicator-custom-territory/${indicatorId}/geojson`;
        return this.postBackEnd(path, null, data);
    }

    getCustomTerritoryIndicatorValues(indicatorId: number, data: any) {
        const path = `/indicator-custom-territory/${indicatorId}/values`;
        return this.postBackEnd(path, null, data);
    }

    getCustomTerritoryIndicatorFiltersValue(indicatorId: number, data: any) {
        const path = `/indicator-custom-territory/${indicatorId}/filters-value/`;
        return this.postBackEnd(path, null, data);
    }

    // getCustomTerritoryIndicatorsValues(indicatorId: number, parameters: any) {
    //     // const params = this.usefulService.encodeForUrl(parameters);
    //     const path = `/indicator-custom-territory/${indicatorId}/singleter/`;
    //     return this.getBackEnd(path, parameters);
    // }

    //################################ Map ###################################################################

    getGeoTerritoryScales(parameters: { year: number }) {
        const path = `/territory-scales`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGeoRasters() {
        const path = `/rasters`;
        return this.getGeoBackEnd(path);
    }

    getGeoYears() {
        const path = `/years`;
        return this.getGeoBackEnd(path);
    }

    getPrGeojson(parameters: any) {
        const path = `/prosper-reseaux/geojson`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGeojson(data: any) {
        const path = `/territories/geojson`;
        return this.postGeoBackEnd(path, null, data);
    }

    getGeoLabelsByScale(parameters: {
        year: number;
        scaleTypeId: number;
        scaleTypeIdLimit?: number;
        territoryIds?: string;
    }) {
        const path = `/territories/labels-by-scale`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGeoPrLabels(parameters: {
        year: number;
        energyId: string;
        typeId: string;
        territoryScaleTypeId: number;
        filterIds: string;
    }) {
        const path = `/prosper-reseaux/labels`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGeoTerritoryLabels(parameters: { year: number; scaleTypeId: number; territoryIds: string }) {
        const path = `/territories/label-by-territories`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGeoBbox(parameters: { territoryIds: string; scaleTypeId: number; year: number }) {
        const path = `/territories/bbox`;
        return this.getGeoBackEnd(path, parameters);
    }

    getGetTerritorryLabelByLocation(parameters: {
        scaleTypeId: number;
        year: number;
        latitude: number;
        longitude: number;
    }) {
        const path = `/territories/label-by-location`;
        return this.getGeoBackEnd(path, parameters);
    }

    //################################ Administration  #################################################################

    //============================== group =================================================================//

    createGroup(data: any) {
        const path = `/groups/`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    getAllGroups() {
        const path = `/groups`;
        return this.getBackEnd(path, null, this.criticError);
    }

    getGroup(groupId: number) {
        const path = `/groups/${groupId}`;
        return this.getBackEnd(path, null, this.criticError);
    }

    updateGroup(groupId: number, data: any) {
        const path = `/groups/${groupId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    assignIndicatorsToGroupUsers(groupId: number) {
        const path = `/groups/${groupId}/assignIndicatorsToGroupUsers`;
        return this.putBackEnd(path, null, this.criticError);
    }

    deleteGroup(groupId: number) {
        const path = `/groups/${groupId}`;
        return this.deleteBackEnd(path);
    }

    //============================== user =================================================================//

    createUser(data: any) {
        const path = `/users`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    getAllUsers() {
        const path = `/users`;
        return this.getBackEnd(path, null, this.criticError);
    }

    getUser(userId: number) {
        const path = `/users/${userId}`;
        return this.getBackEnd(path, null, this.criticError);
    }

    updateUser(userId: number, data: any) {
        const path = `/users/${userId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    deleteUser(userId: number) {
        const path = `/users/${userId}`;
        return this.deleteBackEnd(path);
    }

    resetPassword(data: { login: string }) {
        const path = `/users-reset-password`;
        return this.putBackEnd(path, data);
    }

    //============================== base =================================================================//

    getBases() {
        const path = `/indicators/bases`;
        return this.getBackEnd(path, null, this.criticError);
    }

    updateBase(data: any) {
        const path = `/indicators/bases`;
        return this.putBackEnd(path, data, this.criticError);
    }

    //============================== indicator =================================================================//

    getIndicators() {
        const path = `/indicators/user-library`;
        return this.getBackEnd(path, null, this.criticError);
    }

    getIndicatorsReference() {
        return this.getBackEnd(`/indicators/admin-library`, this.criticError);
    }

    //============================== indicator theme =================================================================//

    createTheme(data: { libelle: string }) {
        const path = `/indicators/themes`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    updateTheme(data: any) {
        const path = `/indicators/themes`;
        return this.putBackEnd(path, data, this.criticError);
    }

    //============================== indicator sub theme =================================================================//

    createSubTheme(data: { id_theme: number; libelle: string; ordre: number }) {
        const path = `/indicators/subthemes`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    getAllSubThemes() {
        const path = `/indicators/subthemes`;
        return this.getBackEnd(path, this.criticError);
    }

    //============================== indicator filter =================================================================//

    createFilter(data: any) {
        const path = `/indicators/filters`;
        return this.postBackEnd(path, null, data, this.criticError);
    }

    updateFilter(filterId: number, data: any) {
        const path = `/indicators/filters/${filterId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    deleteFilter(archiCritdterId: number) {
        const path = `indicators/filter/${archiCritdterId}`;
        return this.deleteBackEnd(path, null, this.criticError);
    }

    getFiltersAdmin(): Promise<any[]> {
        const path = `/indicators/filters/admin-library`;
        return this.getBackEnd(path, null, this.criticError);
    }

    getFilter(archiCritdterId: number) {
        const path = `/indicators/filters/${archiCritdterId}`;
        return this.getBackEnd(path, null, this.criticError);
    }

    //============================== indicator filter criteria ======================================================//

    updateFilterCategory(categoryId: number, data: any) {
        const path = `indicators/filters/criteria/${categoryId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    deleteFilterCategory(categoryId: number, parameters: { filterId: number }) {
        const path = `/indicators/filters/criteria/${categoryId}`;
        return this.deleteBackEnd(path, parameters, this.criticError);
    }

    //============================== indicator dter =================================================================//

    updateFilterCriteria(criteriaId: number, data: any) {
        const path = `/indicators/filters/dter/${criteriaId}`;
        return this.putBackEnd(path, data, this.criticError);
    }

    deleteFilterCriteria(criteriaId: number, parameters: { filterId: number; categoryId: number }) {
        const path = `/indicators/filters/dter/${criteriaId}`;
        return this.deleteBackEnd(path, parameters, this.criticError);
    }

    //################################ Scenarios  ######################################################################

    createScenario(data: any) {
        const path = `/scenarios`;
        return this.postBackEnd(path, null, data);
    }

    getScenarios() {
        const path = '/scenarios';
        return this.getBackEnd(path);
    }

    getScenario(scenarioId: number) {
        const path = `/scenarios/${scenarioId}`;
        return this.getBackEnd(path);
    }

    updateScenario(scenarioId: number, data: any) {
        const path = `/scenarios/${scenarioId}`;
        return this.putBackEnd(path, data);
    }

    replaceScenario(scenarioId: number, data: any) {
        const path = `/scenarios/replace/${scenarioId}`;
        return this.putBackEnd(path, data);
    }

    deleteScenario(scenarioId: number) {
        const path = `/scenarios/${scenarioId}`;
        return this.deleteBackEnd(path);
    }

    //################################ Courbes de charge ################################################################

    getDataCdc(granularity: string, id: string, step: string, type: string, filters: any) {
        const path = `/cdc/data?granularity=${granularity}&id=${id}&type=${type}&step=${step}`;
        return this.postBackEnd(path, null, filters);
    }

    getAvailableFiltersCdc(granularity: string, id: string, type: string) {
        const path = `/cdc/filtres?granularity=${granularity}&id=${id}&type=${type}`;
        return this.getBackEnd(path);
    }

    // ################################ Prosper Réseaux #############################################################################

    getPrIndicatorValues(
        indicatorId: number,
        data: {
            scaleTypeId: number;
            territoryIds: string[];
            hypothesisInputs: any;
            filters: Array<string>;
        },
    ) {
        const path = `/prosper-reseaux/indicator/${indicatorId}/values`;
        return this.postBackEnd(path, null, data);
    }

    getPrHypothesisParameters(parameters: { indicatorId: number }): Promise<PrParameter[]> {
        const path = `/prosper-reseaux/hypothesis/parameters`;
        return this.getBackEnd(path, parameters);
    }

    getPrHypothesisValues(parameters: { typeId: string; id: string }) {
        const path = `/prosper-reseaux/hypothesis/values`;
        return this.getBackEnd(path, parameters);
    }

    getPrLineElements(data: {
        elementId: string;
        typeId: string;
        stream: string;
        indicatorId: number;
        scaleTypeId: number;
        territoryIds: string[];
    }) {
        const path = `/prosper-reseaux/line-elements`;
        return this.postBackEnd(path, null, data);
    }

    async getPrProsperActionsScenarios() {
        const prosperActionsUrl = JSON.parse(localStorage.getItem('prosper_url'));
        const url = `${prosperActionsUrl}/scenario/listScenarios`;
        const options = {
            headers: {
                login: 'admin',
            },
        };

        try {
            const result: any = await lastValueFrom(this.http.get(url, options));
            return result;
        } catch (error) {
            console.error('Error getPrProsperActionsScenarios', error);
            throw error;
        }
    }

    getPrProsperScenarioByYear(data: {
        scaleTypeId: number;
        territoryIds: string[];
        scenarioId: number;
    }) {
        const path = `/prosper-reseaux/prosper-actions/scenarios/`;
        return this.postBackEnd(path, null, data);
    }

    // ################################ Cadastre Solaire #############################################################################

    saveSolarLogIn(data: { userId: number; label: string; latitude: number; longitude: number }) {
        const path = `/cadastre-solaire/save-login`;
        return this.postBackEnd(path, null, data);
    }

    getSolarGeojson(
        indicatorId: number,
        data: {
            latitude?: number;
            longitude?: number;
            radius?: number;
            minLat?: number;
            maxLat?: number;
            minLng?: number;
            maxLng?: number;
            scaleTypeId: number;
            territoryIds: string[];
        },
    ) {
        const path = `/cadastre-solaire/indicator/${indicatorId}/geojson`;
        return this.postBackEnd(path, null, data);
    }

    getSolarIndicatorValue(indicatorId: number, data: any) {
        const path = `/cadastre-solaire/indicator/${indicatorId}/values`;
        return this.postBackEnd(path, null, data);
    }

    getSolarElementInfo(parameters: any) {
        const path = `/cadastre-solaire/element`;
        return this.getBackEnd(path, parameters);
    }

    getSolarCustomTerritoryInfo(parameters: any) {
        const path = `/cadastre-solaire/custom-territory-info`;
        return this.getBackEnd(path, parameters);
    }

    getHousingDefaultParameters(parameters?: any) {
        const path = `/cadastre-solaire/pv/housing/default-parameters`;
        return this.getBackEnd(path, parameters);
    }

    getHousingElectricityConsumption(parameters?: any) {
        const path = `/cadastre-solaire/pv/housing/electric-consumption`;
        return this.getBackEnd(path, parameters);
    }

    getDefaultMobilityUses(parameters?: any) {
        const path = `/cadastre-solaire/pv/electric-vehicule-uses`;
        return this.getBackEnd(path, parameters);
    }

    getAutoconsumption(data: any) {
        const path = `/cadastre-solaire/pv/autoconsumption`;
        return this.postBackEnd(path, null, data);
    }

    getConnectionCost(data: any) {
        const path = `/cadastre-solaire/pv/connection-cost`;
        return this.postBackEnd(path, null, data);
    }

    getSellConnectionCost(data: any) {
        const path = `/cadastre-solaire/pv/connection-cost/sell`;
        return this.postBackEnd(path, null, data);
    }

    getAutoconsumptionConnectionCost(data: any) {
        const path = `/cadastre-solaire/pv/connection-cost/autoconsumption`;
        return this.postBackEnd(path, null, data);
    }

    async getSolarPdfFile(data: any) {
        const path = `${this.backEndUrl}/cadastre-solaire/export-pdf`;
        const promise = this.http.post(path, data, {
            headers: { accept: 'application/pdf' },
            responseType: 'arraybuffer',
        });

        try {
            const result = await lastValueFrom(promise);
            return result;
        } catch (error) {
            console.error('Error getSolarPdfFile', error);
            throw error;
        }
    }

    simulateThermal(data: any) {
        const path = `/cadastre-solaire/thermal/simulation`;
        return this.postBackEnd(path, null, data);
    }

    simulatePvTotality(data: any) {
        const path = `/cadastre-solaire/pv/totality/simulation`;
        return this.postBackEnd(path, null, data);
    }

    simulatePvSurplus(data: any) {
        const path = `/cadastre-solaire/pv/self-consumption/simulation`;
        return this.postBackEnd(path, null, data);
    }

    async downloadPvSurplus(data: any) {
        const path = `${this.backEndUrl}/cadastre-solaire/pv/self-consumption/download`;
        const promise = this.http.post(path, data, {
            headers: {
                accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        });

        try {
            const result = await lastValueFrom(promise);
            return result;
        } catch (error) {
            console.error('Error downloadPvSurplus', error);
            throw error;
        }
    }

    analyseElectricLoad(data: any) {
        const path = `/cadastre-solaire/pv/self-consumption/anaylyse-electric-load`;
        return this.postBackEnd(path, null, data);
    }

    checkConstraintZone(parameters: any) {
        const path = `/cadastre-solaire/is-in-constraint-zone`;
        return this.getBackEnd(path, parameters);
    }

    //==========================OVELO====================================================//

    getOveloValues(data: any) {
        return this.postBackEnd(`/ovelo/values`, null, data);
    }

    //==============================ATAC=================================================================//
    getAtacValues(data: any) {
        return this.postBackEnd(`/autoconso`, null, data);
    }

    getAtacInfoOnMap(terId: string) {
        return this.getBackEnd(`/autoconso/info?id_ter=${terId}`);
    }

    uploadCourbeAtac(terId: string, fileContent: Array<Array<string>>, curveType: string) {
        const data = {
            id_ter: terId,
            data: fileContent,
            type_courbe: curveType,
        };
        return this.postBackEnd(`/autoconso/courbe`, null, data);
    }

    deleteCourbeAtac(terId: string, curveType: string) {
        const path = `/autoconso/courbe?id_ter=${terId}&type_courbe=${curveType}`;
        return this.deleteBackEnd(path);
    }

    //==============================PROSPER BATIMENT=================================================================//

    //Récupération des infos par batiment
    getBatimentInfo(terId: string) {
        return this.getBackEnd(`/prosper/batiment/info?ter_01_2015=${terId}`);
    }

    postNewLot(lot: any) {
        //{lot : {name: ""}, buildings:[{ter_01_2015, init:{}, enrichment:{}}]}
        return this.postBackEnd(`/prosper/batiment/lot`, null, lot);
    }

    getLots(parameters: { scale_type_ter: string; territoryIds: string }) {
        const params = this.usefulService.encodeForUrl(parameters);
        const path = `/prosper/batiment/lots?${params}`;
        return this.getBackEnd(path);
    }

    getLotInfo(lotId: number) {
        //{lot : {id, name, ...}, lot_action:{}, ... buildings:[{ter_01_2015, init:{}, enrichment:{}}]}
        return this.getBackEnd(`/prosper/batiment/lot?id=${lotId}`);
    }

    updateLot(lot: any) {
        //{lot : {id, name, ...}, lot_action:{}, ... buildings:[{ter_01_2015, init:{}, enrichment:{}}]}
        return this.putBackEnd(`/prosper/batiment/lot`, lot);
    }

    deleteLot(lotId: number) {
        //{lot : {id, name, ...}, lot_action:{}, ... buildings:[{ter_01_2015, init:{}, enrichment:{}}]}
        return this.deleteBackEnd(`/prosper/batiment/lot?id=${lotId}`);
    }

    getRefOptions() {
        return this.getBackEnd(`/prosper/batiment/options`);
    }

    getStats(lot: any) {
        const path = `/prosper/batiment/stats/?id=${lot.lot.id}`;
        return this.getBackEnd(path);
    }

    async getAddress(lat: number, lng: number) {
        const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${lng}&lat=${lat}`;
        const promise = this.http.get(url);
        try {
            const result: any = await lastValueFrom(promise);
            return result.features[0];
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    searchAddress(parameters: {
        address: string;
        latitude: number;
        longitude: number;
        cityCode: string;
        year: number;
    }) {
        const path = `/search/address`;
        return this.getBackEnd(path, parameters);
    }

    searchParcel(parameters: { parcelId: string; scaleTypeId: number; territories: string }) {
        const path = `/search/parcel`;
        return this.getBackEnd(path, parameters);
    }

    searchTerritory(parameters: { cityCode: string; scaleTypeId: number; year: number }) {
        const path = `/search/territory`;
        return this.getBackEnd(path, parameters);
    }

    searchReverseAddress(parameters: { latitude: number; longitude: number }) {
        const path = `/search/reverse-address`;
        return this.getBackEnd(path, parameters);
    }
}
